/**
 * Helper function to import options
 * @param {object} object  Reference object
 * @param {object} options Options object
 */
var ImportOptions = function(object, options) {
    for (var key in options) {
        object[key] = options[key];
    }
};

module.exports = ImportOptions;