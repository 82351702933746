var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Articles
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsArticle = function(options) {
    this.init(options);
}

SpecsArticle.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * UI references
     * @type {object}
     */
    ui: {
        overviewProductLink: '.product-list a',
        productDetailActionLink: '.product-nav a',
        navHeadBackLink: '.nav-head--back a',
        navHeadProductLink: '.nav-head--others a',
        productDetailColorSelect: '#product-color-selection',
        productDetailSizeOption: '.product-variants a',

        frameNotificationOpenButton: '.js-open-frame-notification',
        frameNotificationForm: '.js-frame-notification-form',
    },

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @return {SpecsArticle}
     */
    bindUiEvents: function() {
        this.$body.on('click', this.ui.overviewProductLink, this.handleProductOverviewLinkClick.bind(this));
        this.$body.on('click', this.ui.navHeadBackLink, this.handleProductDetailBackLinkClick.bind(this));

        // Handle clicks in product listing or on detail page
        this.$body.on('click', [
            this.ui.productDetailActionLink,
            this.ui.navHeadProductLink,
        ].join(','), this.handleProductLinkClick.bind(this));

        // Handle config changes for size and color
        this.$body.on('change', this.ui.productDetailColorSelect, this.handleProductDetailColorSelect.bind(this));
        this.$body.on('click', this.ui.productDetailSizeOption, this.handleProductDetailSizeSelect.bind(this));

        // Frame notification related
        this.$body.on('click', this.ui.frameNotificationOpenButton, this.handleFrameNotificationOpenClick.bind(this));
        this.$body.on('submit', this.ui.frameNotificationForm, this.handleFrameNotificationFormSubmit.bind(this));

        this.$win.on('resize', this.handleResize.bind(this));
        this.$doc.ready(this.handleResize.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {SpecsArticle}
     */
    bindCustomEvents: function() {
        this.$win.on('popstate', this.handleStateChange.bind(this));
        Postal.channel('common').subscribe('pushstate', this.handleStateChange.bind(this));

        return this;
    },

    /**
     * Handles the resize event for the product detail page
     */
    handleResize: function() {
        var $product = $('article.product');
        var $productInfoWrapper = $product.find('.product-info-wrapper');

        if ($product.length === 0 || $productInfoWrapper.length === 0) {
            return;
        }

        // Remove extended height class
        $product.removeClass('has-extended-height');

        // Skip for mobile and tablet
        if (window.innerWidth < 900) {
            return;
        }

        var $productCredentials = $productInfoWrapper.find('.product-credentials');
        var $productForm = $productInfoWrapper.find('.product-form');

        var headerHeight = Number($('html').css('fontSize').replace('px', '')) * 8;
        var relatedWinHeight = window.innerHeight * 0.9;

        if ($productCredentials.outerHeight(true) + $productForm.outerHeight(true) <= Math.ceil(relatedWinHeight - headerHeight)) {
            $product.addClass('has-extended-height');
        }
    },

    /**
     * Fetch the content for the clicked product link
     * @param {object} event The click event
     */
    handleProductOverviewLinkClick: function(event) {
        event.preventDefault();

        // Set scroll position
        var currentSite = window.location.pathname.replace(/\/+$/, '') + window.location.search;
        this.common.scrollPositions[currentSite] = this.$doc.scrollTop();

        var target = $(event.currentTarget),
            href   = target.attr('href').replace(window.location.origin, '');

        // Push new state
        this.common.pushState(href, {
            type: 'product-detail',
        });
    },

    /**
     * Fetch the content for the clicked product link
     * @param {object} event The click event
     */
    handleProductDetailBackLinkClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = target.attr('href').replace(window.location.origin, '');

        var scrollPosition = this.common.scrollPositions[href.replace(/\/+$/, '')] || -1;

        // Push new state
        this.common.pushState(href, {
            type: 'product-overview',
            scrollPosition: scrollPosition,
        });
    },

    /**
     * Fetch the content for the clicked product link
     * @param {object} event The click event
     */
    handleProductLinkClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = target.attr('href').replace(window.location.origin, '');

        // Push new state
        this.common.pushState(href, {
            type: 'product-detail',
        });
    },

    /**
     * Fetch the content for the selected color / article
     * @param {object} event The change event
     */
    handleProductDetailColorSelect: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = this.common.getProperUrl(target.children(":selected").data('url'));

        // Push new state
        this.common.pushState(href, {
            type: 'product-detail',
        });
    },

    /**
     * Fetch the content for the selected size
     * @param {object} event The change event
     */
    handleProductDetailSizeSelect: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = this.common.getProperUrl(target.attr('href'));

        // Push new state
        this.common.pushState(href, {
            type: 'product-detail',
        });
    },

    /**
     * Handles the click event to open the frame notification request
     * @param {Event} event The click event
     */
    handleFrameNotificationOpenClick: function(event) {
        event.preventDefault();

        var $target = $(event.currentTarget);
        var ordernumber = $target.data('ordernumber');
        var $modal = $('.modal--frame-notification');
        $modal.find('[name="ordernumber"]').val(ordernumber);
        $modal.find('[name="email"]').val('');
        $modal.find('[name="privacy"]').prop('checked', false);
        $modal.find('form').removeClass('is-success is-error');

        this.$body.addClass('has-frame-notification');

        $modal.one('transitionend', function() {
            $modal.find('[name="email"]').focus();
        });
    },

    /**
     * Handles the submission of the frame notification form
     * @param {Event} event The submit event
     */
    handleFrameNotificationFormSubmit: function(event) {
        event.preventDefault();

        var $form = $(event.currentTarget);
        var $formResponse = $form.find('.frame-notification__response');
        var data = $form.serialize();

        $form.removeClass('is-success is-error');
        $formResponse.removeClass('is-error');

        // Perform request
        var request = $.ajax($form.attr('action'), {
            method: 'POST',
            data: data,
            dataType: 'json',
        });

        // Handle successful request
        request.done(function(response) {
            if (response.success) {
                $form.addClass('is-success');
            } else {
                $form.addClass('is-error');
            }

            if (response.message) {
                $formResponse.html('<p>' + response.message + '</p>');
            }
        }.bind(this));

        request.always(function() {
            this.common.hideMainLoader();
            $form.find('input[type="submit"], button[type="submit"]').prop('disabled', false);
        }.bind(this));
    },

    /**
     * Handles the state change event
     * @param {object} event The state change event
     */
    handleStateChange: function(event) {
        event = event.originalEvent || event;

        var stateType = null;

        if (event.state) {
            if (event.state.type) {
                stateType = event.state.type;
            }
        }

        // Type is product-detail? -> Fetch content
        if (stateType === 'product-detail') {
            // Fetch the content
            this.common.fetchContent(event.state.url, {
                scrollTop: true,
                successCallback: function() {
                    this.handleResize();
                }.bind(this),
            });
        } else if (stateType === 'product-overview') {
            // Fetch the content
            var scrollPosition = this.common.scrollPositions[event.state.url.replace(/\/+$/, '')];
            this.common.fetchContent(event.state.url, {
                scrollPosition: scrollPosition || event.state.scrollPosition || -1,
            });
        }
    },
};

module.exports = SpecsArticle;