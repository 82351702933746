// Add jquery
var $ = require('jquery');

// Add specs deps
var SpecsCommon          = require('specs/common');
var SpecsSite            = require('specs/site');
var SpecsStartPage       = require('specs/start-page');
var SpecsFilter          = require('specs/filter');
var SpecsSearch          = require('specs/search');
var SpecsLazyload        = require('specs/lazyload');
var SpecsCheckout        = require('specs/checkout');
var SpecsArticle         = require('specs/article');
var SpecsAuthentication  = require('specs/authentication');
var SpecsCustomer        = require('specs/customer');
var SpecsTracking        = require('specs/tracking');

var HeidelpayHelper      = require('helper/heidelpay');
var HelperBrowserClasses = require('helper/browser-classes');

// Add SW deps
require('vendor/jquery.csrf-protection');

/**
 * Definie Specs entry point
 * @constructor
 */
var Specs = function() {
    // Create references
    var references = {
        $body: SpecsCommon.getBody(),
        $doc : SpecsCommon.getDocument(),
        $win : SpecsCommon.getWindow(),
        $html: SpecsCommon.getHtml(),
    };

    // Attach Browser classes
    HelperBrowserClasses.attach();

    // Init specs common
    SpecsCommon.init();

    // Init components
    this.Site = new SpecsSite(references);
    this.SpecsStartPage = new SpecsStartPage(references);
    this.Filter = new SpecsFilter(references);
    this.Search = new SpecsSearch(references);
    this.Lazyload = new SpecsLazyload(references);
    this.Checkout = new SpecsCheckout(references);
    this.Article = new SpecsArticle(references);
    this.Authentication = new SpecsAuthentication(references);
    this.Customer = new SpecsCustomer(references);
    this.Tracking = new SpecsTracking(references);

    // Heidelpay
    this.HeidelpayHelper = new HeidelpayHelper(references);

    $(window.document).on('ready', function() {
        SpecsCommon.getBody().addClass('is-ready');
    });

    $(window).on('load', function() {
        SpecsCommon.getBody().addClass('is-loaded');
    });

    // Bind reloadable CSS to Alt + R
    if (window.location.host && window.location.host === 'dev.specs-berlin.de') {
        $(document).keyup(function(e) {
            if (e.keyCode == 82 && e.altKey) {
                $('link').each(function(n, el) {
                    var $link = $(el);
                    if ($link.attr('href').match(/specs.min/)) {
                        var rand = Math.round(Math.random() * 10000);
                        var $clone = $link.clone();
                        $clone.attr({
                            href: '/themes/Frontend/SpecsBerlin/assets/css/specs.min.' + rand + '.css',
                        });

                        $link.remove();
                        $('body').append($clone);
                    }
                });
            }
        }.bind(this));
    }
};

window.Specs = new Specs();

module.exports = window.Specs;
