var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var Heidelpay = function(options) {
    this.init(options);
};

Heidelpay.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * Current payment method
     * @type {string}
     */
    pm: null,

    /**
     * The target orign of an iframe
     * @type {string}
     */
    targetOrigin: null,

    /**
     * Indicates, if the creditcard input is valid
     * The value will be changed through onReceiveIframeMessage
     * @type {boolean}
     */
    creditcardValid: false,

    /**
     * UI references
     * @type {object}
     */
    ui: {
        gatewayForm: '.hp-gateway-form',
        gatewayIframe: '.hp-gateway-iframe',
        gatewayFormSue: '.hp-gateway-form--sue',
        sueSepaSwitch: '.hp-gateway-form--sue input[name="hpdd_sepa"]',
        errorMessages: '.heidelpay-errors',
        errorMessage: '.heidelpay-error',
    },

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();

        // Get payment method
        if ($(this.ui.gatewayForm).length > 0) {
            this.pm = $(this.ui.gatewayForm).data('pm');
        }

        // Get iframe origin
        if ($(this.ui.gatewayIframe).length > 0) {
            this.targetOrigin = this.getDomainFromUrl($(this.ui.gatewayIframe).attr('src'));
        }
    },

    /**
     * Binds UI events, when ready is fired
     * @return {Heidelpay}
     */
    bindUiEvents: function() {
        // Handle general submit of the form
        this.$body.on('submit', '.hp-gateway-form', this.handleHeidelpayGatewayForm.bind(this));

        // Bind sepa / account switch
        this.$body.on('change', this.ui.sueSepaSwitch, this.handleSofortAccountChange.bind(this));

        if ($('.hp-gateway-form--cc').length > 0) {
            // Bind receive message
            if (window.addEventListener) { // W3C DOM
                window.addEventListener('message', this.onReceiveIframeMessage.bind(this));
            } else if (window.attachEvent) { // IE DOM
                window.attachEvent('onmessage', this.onReceiveIframeMessage.bind(this));
            }
        } else if ($('.hp-gateway-form--dc').length > 0) {
            this.handleDebitCardInput();
        }

        // Is paypal? -> Auto submit
        if (($(this.ui.gatewayForm).hasClass('hp-gateway-form--pay')
            && $('#contactMail').val().length > 0)
        ) {
            $(this.ui.gatewayForm).submit();
        }

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {Heidelpay}
     */
    bindCustomEvents: function() {

        return this;
    },

    /**
     * Handles the submit event of the heidelpay payment form
     * @param {object} event The submit event
     */
    handleHeidelpayGatewayForm: function(event) {
        var $curTarget = $(event.currentTarget),
            hasError = false;

        // CC Checks
        if ($curTarget.hasClass('hp-gateway-form--cc')) {
            // Validate creditcard
            this.validateCreditCard($curTarget);
            hasError = !this.creditcardValid;
        } else if ($curTarget.hasClass('hp-gateway-form--dc')) {
            hasError = this.validateDebitCard($curTarget);
        } else if ($curTarget.hasClass('hp-gateway-form--dir')) {
            hasError = this.validateGiroPay($curTarget);
        } else if ($curTarget.hasClass('hp-gateway-form--sue')) {
            hasError = this.validateSofortPayment($curTarget);
        }

        // Do we have errors?
        if (hasError) {
            event.preventDefault();

            // Dont show default errors for creditcard
            if (!$curTarget.hasClass('hp-gateway-form--cc')) {
                $(this.ui.errorMessages).addClass('is-visible');
                $(this.ui.errorMessages).find('.msg_fill').addClass('is-visible');
            }
        } else {
            $(this.ui.errorMessages).removeClass('is-visible');
            $(this.ui.errorMessage).removeClass('is-visible');

            if (!$(this.ui.gatewayForm).hasClass('hp-gateway-form--pay')) {
                this.showFormLoader();
            }
        }
    },

    /**
     * Validates creditcard input from the iframe
     * We will send a message and receive the result through onReceiveIframeMessage
     * @param {object} target The form target
     */
    validateCreditCard: function(target) {
        var paymentFrameForm = document.getElementsByName('heidelpay');

        var data = {};
        for (var i = 0, len = paymentFrameForm.length; i < len; ++i) {
            var input = paymentFrameForm[i];
            if (input.name && !input.disabled) {
                data[input.name] = input.value;
            }
        }

        // Add csrf token
        data['__csrf_token'] = $('input[name="__csrf_token"]').val();

        // Show form loader
        this.showFormLoader();

        // Post message
        $(this.ui.gatewayIframe)[0].contentWindow.postMessage(JSON.stringify(data), this.targetOrigin);
    },

    /**
     * Validates creditcard input
     * @param {object} target The form target
     * @returns {boolean} True, if data is not valid
     */
    validateDebitCard: function(target) {
        var hasError;

        var inputCardNumber = $('input[name="ACCOUNT.NUMBER"]'),
            inputExpires = $('input[name="expires"]'),
            inputVerification = $('input[name="ACCOUNT.VERIFICATION"]');

        // Check cardnumber
        var cardNumberValid = inputCardNumber.val().length >= 12;

        // Check expiration
        var cardExpiresDate = $.payment.cardExpiryVal(inputExpires.val());
        var cardExpiresIsValid = $.payment.validateCardExpiry(cardExpiresDate.month, cardExpiresDate.year);

        // Check CVV
        var cardCvvIsValid = inputVerification.val().length >= 3;

        // Update hasError
        hasError = !cardNumberValid || !cardExpiresIsValid || !cardCvvIsValid;

        // Card number and type
        if (!cardNumberValid) {
            inputCardNumber.addClass('has-error');
            $(this.ui.errorMessages).find('.msg_crdnr').addClass('is-visible');
        } else {
            inputCardNumber.removeClass('has-error');
            $(this.ui.errorMessages).find('.msg_crdnr').removeClass('is-visible');
        }

        // Expiration date
        if (!cardExpiresIsValid) {
            inputExpires.addClass('has-error');
        } else {
            inputExpires.removeClass('has-error');
            $('input[name="ACCOUNT.EXPIRY_MONTH"]').val(cardExpiresDate.month);
            $('input[name="ACCOUNT.EXPIRY_YEAR"]').val(cardExpiresDate.year);
        }

        // Verification
        if (!cardCvvIsValid) {
            inputVerification.addClass('has-error');
            $(this.ui.errorMessages).find('.msg_cvv').addClass('is-visible');
        } else {
            inputVerification.removeClass('has-error');
            $(this.ui.errorMessages).find('.msg_cvv').removeClass('is-visible');
        }

        return hasError;
    },

    /**
     * Validates giropay input
     * @param {object} target The form target
     * @returns {boolean} True, if data is not valid
     */
    validateGiroPay: function(target) {
        var hasError;

        var inputIban = $('#hpGirIban'),
            inputBic  = $('#hpGirBic');

        // Check iban
        var ibanValid = this.validateIban(inputIban.val());

        // Check Bic
        var bicValid = this.validateBic(inputBic.val());

        // Update hasError
        hasError = !ibanValid || !bicValid;

        // Iban
        if (!ibanValid) {
            inputIban.addClass('has-error');
            $(this.ui.errorMessages).find('.msg_iban').addClass('is-visible');
        } else {
            inputIban.removeClass('has-error');
            $(this.ui.errorMessages).find('.msg_iban').removeClass('is-visible');
        }

        // Bic
        if (!bicValid) {
            inputBic.addClass('has-error');
            $(this.ui.errorMessages).find('.msg_bic').addClass('is-visible');
        } else {
            inputBic.removeClass('has-error');
            $(this.ui.errorMessages).find('.msg_bic').removeClass('is-visible');
        }

        return hasError;
    },

    /**
     * Validates giropay input
     * @param {object} target The form target
     * @returns {boolean} True, if data is not valid
     */
    validateSofortPayment: function(target) {
        var hasError;
        var isIban = $(this.ui.sueSepaSwitch).filter(':checked').val() == 'iban';

        var inputAccount = $('#hpSueAccount'),
            inputBank = $('#hpSueBank'),
            inputIban = $('#hpSueIban'),
            inputBic  = $('#hpSueBic');


        // Reset invalid states
        $([
            this.ui.errorMessages + '.msg_iban',
            this.ui.errorMessages + '.msg_bic',
            this.ui.errorMessages + '.msg_account',
            this.ui.errorMessages + '.msg_bank',
        ].join(',')).removeClass('is-visible');

        if (isIban) {
            // Remove account errors
            $(this.ui.errorMessages).find('.msg_account').removeClass('is-visible');
            $(this.ui.errorMessages).find('.msg_bank').removeClass('is-visible');

            // Check iban
            var ibanValid = this.validateIban(inputIban.val());

            // Check Bic
            var bicValid = this.validateBic(inputBic.val());

            // Update hasError
            hasError = !ibanValid || !bicValid;

            // Iban
            if (!ibanValid) {
                inputIban.addClass('has-error');
                $(this.ui.errorMessages).find('.msg_iban').addClass('is-visible');
            } else {
                inputIban.removeClass('has-error');
                $(this.ui.errorMessages).find('.msg_iban').removeClass('is-visible');
            }

            // Bic
            if (!bicValid) {
                inputBic.addClass('has-error');
                $(this.ui.errorMessages).find('.msg_bic').addClass('is-visible');
            } else {
                inputBic.removeClass('has-error');
                $(this.ui.errorMessages).find('.msg_bic').removeClass('is-visible');
            }
        } else {
            // Remove sepa errors
            $(this.ui.errorMessages).find('.msg_iban').removeClass('is-visible');
            $(this.ui.errorMessages).find('.msg_bic').removeClass('is-visible');

            // Check account
            var accountValid = this.validateAccount(inputAccount.val());

            // Check bank
            var bankValid = this.validateBank(inputBank.val());

            // Update hasError
            hasError = !accountValid || !bankValid;

            // Account
            if (!accountValid) {
                inputAccount.addClass('has-error');
                $(this.ui.errorMessages).find('.msg_account').addClass('is-visible');
            } else {
                inputAccount.removeClass('has-error');
                $(this.ui.errorMessages).find('.msg_account').removeClass('is-visible');
            }

            // Bank
            if (!bankValid) {
                inputBank.addClass('has-error');
                $(this.ui.errorMessages).find('.msg_bank').addClass('is-visible');
            } else {
                inputBank.removeClass('has-error');
                $(this.ui.errorMessages).find('.msg_bank').removeClass('is-visible');
            }
        }

        return hasError;
    },

    /**
     * Formats the creditcard input
     */
    handleCreditCardInput: function() {
        if ($('input[name="ACCOUNT.NUMBER"]').length > 0) {
            $('input[name="ACCOUNT.NUMBER"]').payment('formatCardNumber');
        }

        if ($('input[name="expires"]').length > 0) {
            $('input[name="expires"]').payment('formatCardExpiry');
        }

        if ($('input[name="ACCOUNT.VERIFICATION"]').length > 0) {
            $('input[name="ACCOUNT.VERIFICATION"]').payment('formatCardCVC');
        }
    },

    /**
     * Formats the debitcard input
     */
    handleDebitCardInput: function() {
        if ($('input[name="expires"]').length > 0) {
            $('input[name="expires"]').payment('formatCardExpiry');
        }
    },

    /**
     * Validates the iban number
     * @param {string} iban The iban to validate
     * @returns {boolean} True, if iban is valid
     */
    validateIban: function(iban) {
        iban = iban.trim();

        var regexIban = new RegExp('^[A-Z]{2}[0-9]{2}[a-zA-Z0-9]{11,30}$');

        return regexIban.test(iban);
    },

    /**
     * Validates the bic number
     * @param {string} bic The bic to validate
     * @returns {boolean} True, if bic is valid
     */
    validateBic: function(bic) {
        bic = bic.trim();

        var regexBic = new RegExp('^[a-zA-Z]{6}[a-zA-Z0-9]{2,5}$');

        return regexBic.test(bic);
    },

    /**
     * Validates the account number
     * @param {string} account The account to validate
     * @returns {boolean} True, if account is valid
     */
    validateAccount: function(account) {
        account = account.trim();

        var regexAcc = new RegExp('^[0-9]{6,16}$');

        return regexAcc.test(account);
    },

    /**
     * Validates the bank number
     * @param {string} bank The bank to validate
     * @returns {boolean} True, if bank is valid
     */
    validateBank: function(bank) {
        bank = bank.trim();

        var regexBank = new RegExp('^[0-9]{5,8}$');

        return regexBank.test(bank);
    },

    /**
     * Handles the account type change for sofort payment
     * @param {object} event The change event
     */
    handleSofortAccountChange: function(event) {
        var curTarget = $(event.currentTarget);
        $('.hp-payment-sue').attr('data-state', curTarget.val());
    },

    /**
     * Is called, when our document receives a message from the iframe
     * @param {object} event The message event
     */
    onReceiveIframeMessage: function(event) {
        // Check to make sure that this message came from the correct domain
        if (event.origin !== this.targetOrigin) {
            return;
        }

        // Get message
        var recMsg = JSON.parse(event.data);

        // Result was okay
        if (recMsg['PROCESSING.RESULT'] == 'ACK'
            && recMsg['FRONTEND.PREVENT_ASYNC_REDIRECT'].toLowerCase() == 'true'
        ) {
            // Mark form as loading
            this.showFormLoader();

            // Retrigger from, because the result is valid now
            this.creditcardValid = true;
            $(this.gatewayForm).submit();
        } else {
            // Hide form loading
            this.hideFormLoader();
        }

        // Error handling
        var errorParams = [];
        if (typeof recMsg['PROCESSING.MISSING.PARAMETERS'] !== 'undefined') {
            errorParams = errorParams.concat(recMsg['PROCESSING.MISSING.PARAMETERS']);
        }

        if (typeof recMsg['PROCESSING.WRONG.PARAMETERS'] !== 'undefined') {
            errorParams = errorParams.concat(recMsg['PROCESSING.WRONG.PARAMETERS']);
        }

        // Remove previous error messages
        $(this.ui.errorMessages).removeClass('is-visible');
        $(this.ui.errorMessage).removeClass('is-visible');

        // Display errors
        if (errorParams.length > 0) {
            // Show general error
            $(this.ui.errorMessages).addClass('is-visible');
            $(this.ui.errorMessages).find('.msg_fill').addClass('is-visible');

            // Account holder
            if ($.inArray('account.holder', errorParams) !== -1) {
                $(this.ui.errorMessages).find('.msg_holder').addClass('is-visible');
            }

            // Card no
            if ($.inArray('account.number', errorParams) !== -1) {
                $(this.ui.errorMessages).find('.msg_crdnr').addClass('is-visible');
            }

            // Expiry date
            if ($.inArray('account.expiry_month', errorParams) !== -1
                || $.inArray('account.expiry_year', errorParams) !== -1
            ) {
                $(this.ui.errorMessages).find('.msg_exp').addClass('is-visible');
            }

            // CVV
            if ($.inArray('account.verification', errorParams) !== -1) {
                $(this.ui.errorMessages).find('.msg_cvv').addClass('is-visible');
            }
        }
    },

    /**
     * Retrieves the URL from the iframe
     * @param url
     * @returns {string}
     */
    getDomainFromUrl: function(url) {
        var arr = url.split("/");
        return arr[0] + "//" + arr[2];
    },

    /**
     * Shows the form loader
     */
    showFormLoader: function() {
        // Mark as loading
        $(this.ui.gatewayForm).attr('data-state', 'loading');

        // Disable buttons
        $(this.ui.gatewayForm).find('input[type="submit"], button[type="submit"]').prop('disabled', true);

        this.common.showMainLoader(true);
    },

    /**
     * Shows the form loader
     */
    hideFormLoader: function() {
        // Mark as loading
        $(this.ui.gatewayForm).attr('data-state', 'idle');

        // Disable buttons
        $(this.ui.gatewayForm).find('input[type="submit"], button[type="submit"]').prop('disabled', false);

        this.common.hideMainLoader();
    },
};

module.exports = Heidelpay;