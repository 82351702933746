var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Site
 * This class will handle most of the frontend actions
 * @param {object=} options Options/References to apply
 * @constructor
 */
var StartPage = function(options) {
    this.init(options);
};

StartPage.prototype = {
    /**
     * Holds the jQuery references for a selector
     * @type {Object}
     */
    references: {},

    /**
     * UI ref classes
     */
    ui: {
        brandOverviewContainerListelement      : '.brand-overview .brand-overview-list li',
        brandOverviewContainerListelementButton: '.brand-overview .brand-overview-list li button',
        brandOverviewFilterButton              : '.brand-overview-filter button',
        brandOverviewFilter                    : '.brand-overview-filter',
        productListSlider                      : '.product-list--slider',
    },


    sliderPos: 0,
    sliderPosTo: 0,

    brandOverviewFilteredElements: false,
    brandOverviewFilteredStores: false,


    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);

        // Bind ui events
        this.$doc.on('ready', this.bindUiEvents.bind(this));

        // Bind custom events
        this.bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @returns {SpecsSite}
     */
    bindUiEvents: function() {
        this.$body.on('click', this.ui.brandOverviewFilterButton, this.brandOverviewFilterButtonClick.bind(this));
        this.$body.on('mouseover', this.ui.brandOverviewFilterButton, this.brandOverviewFilterButtonHover.bind(this));
        this.$body.on('mouseout', this.ui.brandOverviewFilterButton, this.brandOverviewFilterButtonHoverOut.bind(this));

        this.$body.on('click', this.ui.brandOverviewContainerListelementButton, this.brandOverviewContainerListelementButtonClick.bind(this));
        this.$body.on('mouseover', this.ui.brandOverviewContainerListelement, this.brandOverviewContainerListelementHover.bind(this));
        this.$body.on('mouseout', this.ui.brandOverviewContainerListelement, this.brandOverviewContainerListelementHoverOut.bind(this));

        this.bindStartpageSlider();
    },

    /**
     * Binds custom events, fired trough postal
     */
    bindCustomEvents: function() {
        Postal.channel('common').subscribe('fetch-content:success', this.handleFetchContentSuccess.bind(this));
    },

    /**
     * Handles the state change event
     * @param {object} data The response object
     */
    handleFetchContentSuccess: function(data) {
        if (data.response && $(data.response).find(this.ui.productListSlider).length > 0) {
            this.bindStartpageSlider();
        }
    },

    brandOverviewContainerListelementHover: function(event) {
        var stores = [];
        for (var store in $(event.currentTarget).data()) {
            if ($(event.currentTarget).data(store)) {
                stores.push(store);
            }
        }

        this.brandOverviewhighlightStore(stores);

        $(event.currentTarget).removeClass("inactive").addClass("active");
    },

    brandOverviewhighlightStore: function(stores) {
        if (stores === false) {
            $(this.ui.brandOverviewFilter).removeClass('filtered');
        }

        $(this.ui.brandOverviewFilter).addClass('filtered');

        $(this.ui.brandOverviewContainerListelement).addClass('inactive').removeClass('active');
        $(this.ui.brandOverviewFilterButton).parent().removeClass("active");

        for (var i in stores) {
            $('[data-for="' + stores[i] + '"]').parent().addClass("active");
        }
    },

    brandOverviewContainerListelementHoverOut: function(event) {
        this.brandOverviewhighlightStore(this.brandOverviewFilteredStores);
        this.brandOverviewFilterActivateElements(this.brandOverviewFilteredElements);
    },

    brandOverviewContainerListelementButtonClick: function(event) {
        event.preventDefault(); // NOP
    },

    brandOverviewFilterButtonHover: function(event) {
        this.brandOverviewFilterActivateElements($(event.currentTarget).data('for').split(' '));
    },

    brandOverviewFilterButtonHoverOut: function(event) {
        this.brandOverviewFilterActivateElements(this.brandOverviewFilteredElements);
    },

    brandOverviewFilterButtonClick: function(event) {
        event.preventDefault();
        this.brandOverviewFilterActivate($(event.currentTarget));
    },

    brandOverviewFilterActivate: function($currentTarget) {
        if ($currentTarget.parent().hasClass('active')) {
            // remove all actives on filter-buttons
            $(this.ui.brandOverviewFilterButton).parents().removeClass('active');

            // remove all list-element-actives
            this.brandOverviewFilteredElements = false;
            this.brandOverviewFilteredStores   = false;
            this.brandOverviewFilterActivateElements(false);
        } else {
            // remove all actives on filter-buttons
            $(this.ui.brandOverviewFilterButton).parents().removeClass('active');
            $currentTarget.parent().addClass('active');
            this.brandOverviewFilteredStores = $currentTarget.data('for').split(' ');

            // add list-element-actives
            this.brandOverviewFilteredElements = $currentTarget.data('for').split(' ');
            this.brandOverviewFilterActivateElements($currentTarget.data('for').split(' '));
        }
    },

    brandOverviewFilterActivateElements: function(activeListElements) {
        if (activeListElements === false) {
            $(this.ui.brandOverviewFilter).removeClass('filtered');
            $(this.ui.brandOverviewContainerListelement).removeClass('inactive').removeClass('active');
            return;
        }

        $(this.ui.brandOverviewFilter).addClass('filtered');
        $(this.ui.brandOverviewContainerListelement).each(function(i, listElement) {
            listElement = $(listElement);

            var active = false;
            $.each(activeListElements, function(k, activeListElement) {
                if (listElement.data(activeListElement.replace('-', '')) == true) {
                    active = true;
                }
            });

            listElement.toggleClass('active', active).toggleClass('inactive', !active);
        });
    },

    moveProductListSlider: function() {
        var listSlider = $(this.ui.productListSlider);
        if (listSlider.length) {
            this.sliderPos -= (this.sliderPos - this.sliderPosTo) / 25;
            var innerList = listSlider.find('.product-list-slider-wrapper');

            innerList.css({
                transform: 'translate3d(' + this.sliderPos + 'px, 0, 0)',
            });

            window.requestAnimationFrame(this.moveProductListSlider.bind(this));
        }
    },

    /**
     * Binds the startpage slider based on device type
     */
    bindStartpageSlider: function() {
        var listSlider = $(this.ui.productListSlider);
        if (!this.$doc.hasClass('mobile') && listSlider.length > 0) {
            var innerList = listSlider.find('.product-list-slider-wrapper');
            var lastElement = innerList.find('article:last-child');

            // Move the slider
            this.moveProductListSlider();

            // Bind mousemove
            $(this.ui.productListSlider).closest('.container').on('mousemove', function(event) {
                var innerWidth  = lastElement.position().left + lastElement.width()
                    + (lastElement.outerWidth(true) - lastElement.outerWidth());
                var diff = innerWidth - listSlider.width();

                this.sliderPosTo = diff * event.clientX / listSlider.width() * (-1);
            }.bind(this));
        }
    },
};

module.exports = StartPage;