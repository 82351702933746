var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsCheckout = function(options) {
    this.init(options);
}

SpecsCheckout.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * UI ref classes
     */
    ui: {
        showLogin: '.showlogin',
        productDetailForm: '.product-detail-form',
        cartIconCount: '.cart-trigger .item-count',
        removeProductFromBasketButton: '[data-action="cart-remove"]',
        miniCart: '.mini-cart',

        // Checkout / Cart
        cartItemForm: '.cart-item-form',
        cartItemFormQuantity: '.cart-item-form input[name="sQuantity"]',
        cartItemFormRemove: '.cart-item-form .cart-remove',

        // Shipping & Payment form
        paymentShippingContainer: '.payment-shipping-container',
        paymentShippingForm: '#payment-shipping-form',
        paymentShippingOptions: '#payment-shipping-form input[type="radio"]',
        paymentShippingItem: '.methods-panel-listing-item',
    },

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind ui events
        this.$doc.on('ready', this.bindUiEvents.bind(this));

        // Bind custom events
        this.bindCustomEvents();

        // Bind load events
        this.$win.on('load', this.initialLoadSidebarBasket.bind(this));
    },

    /**
     * Binds UI events, when ready is fired
     */
    bindUiEvents: function() {
        this.$body.on('submit', this.ui.productDetailForm, this.handleProductFormSubmit.bind(this));
        this.$body.on('click', this.ui.removeProductFromBasketButton, this.handleRemoveFromBasketClick.bind(this));
        this.$body.on('click', this.ui.showLogin, function(event) {
            event.preventDefault();
            $('body').addClass('has-login-box');
        });

        // Handle change of cart item quantity
        this.$body.on('change', this.ui.cartItemFormQuantity, this.handleCartItemQuantityChange.bind(this));
        this.$body.on('blur', this.ui.cartItemFormQuantity, this.handleCartItemQuantityChangeSubmit.bind(this));
        this.$body.on('click', this.ui.cartItemFormRemove, this.handleCartItemRemoveClick.bind(this));

        // Handle change of payment / shipping
        this.$body.on('click', this.ui.paymentShippingItem, this.handlePaymentShippingItemClick.bind(this));
        this.$body.on('change', this.ui.paymentShippingOptions, this.handlePaymentShippingChange.bind(this));
    },

    /**
     * Binds custom events trough postal
     */
    bindCustomEvents: function() {
        Postal.channel('checkout:cart').subscribe('article:added', this.loadSidebarBasket.bind(this));
        Postal.channel('checkout:cart').subscribe('article:removed', this.loadSidebarBasket.bind(this));

        Postal.channel('checkout:cart').subscribe('article:added', this.updateCartIconCount.bind(this));
        Postal.channel('checkout:cart').subscribe('article:removed', this.updateCartIconCount.bind(this));
    },

    /**
     * Handles the add to cart action on product detail
     * @param {object} event The form submit event
     */
    handleProductFormSubmit: function(event) {
        event.preventDefault();

        var curTarget = $(event.currentTarget),
            action = curTarget.attr('action'),
            ordernumber = curTarget.find('input[name="sAdd"]').val(),
            quantity  = Math.abs(Number(curTarget.find('input[name="sQuantity"]').val()));

        if (ordernumber.length > 0 && quantity > 0) {
            curTarget.addClass('form-loading');

            var data = {
                sAdd     : ordernumber,
                sQuantity: quantity,
            };

            var request = $.ajax(action, {
                method: 'POST',
                data: data,
                dataType: 'json',
            });

            // Handle successful request
            request.done(function(response, textStatus, jqXhr) {
                if (response.success) {
                    Postal.channel('checkout:cart').publish('article:added', {
                        ordernumber: ordernumber,
                        quantity   : quantity,
                        response   : response,
                    });
                } else {
                    Postal.channel('checkout:cart').publish('article:add:failed', {
                        ordernumber: ordernumber,
                        quantity   : quantity,
                        response   : response,
                    });
                }
            }.bind(this));

            // Handle fail
            request.fail(function(jqXhr, textStatus, errorThrown) {
                Postal.channel('checkout:cart').publish('article:add:failed', {
                    ordernumber: ordernumber,
                    quantity   : quantity,
                });
            }.bind(this));

            // Remove loading state
            request.always(function() {
                curTarget.removeClass('form-loading');
            });
        }
    },

    /**
     * Updates the article count in the bag icons
     * @param {object} data Data from cart added event
     */
    updateCartIconCount: function(data) {
        data = data || {};

        if (data.response && (data.response.itemCount || data.response.itemCount === 0)) {
            $(this.ui.cartIconCount).html('(' + data.response.itemCount + ')');
        }
    },

    /**
     * Sets basket loading state
     * @param {boolean=} triggerEvent Publish event?
     */
    showBasketLoading: function(triggerEvent) {
        if(typeof(triggerEvent) === "undefined") {
            triggerEvent = true;
        }

        $(this.ui.miniCart).addClass('is-loading');

        if (triggerEvent) {
            Postal.channel('checkout').publish('sidebar-basket:loading');
        }
    },

    /**
     * Removes basket loading state
     * @param {boolean=} triggerEvent Publish event?
     */
    hideBasketLoading: function(triggerEvent) {
        if(typeof(triggerEvent) === "undefined") {
            triggerEvent = true;
        }

        $(this.ui.miniCart).removeClass('is-loading');

        if (triggerEvent) {
            Postal.channel('checkout').publish('sidebar-basket:loaded');
        }
    },

    /**
     * Initial load of basket
     */
    initialLoadSidebarBasket: function() {
        this.loadSidebarBasket(false);
    },

    /**
     * Loads the sidebar basket content
     */
    loadSidebarBasket: function(triggerEvent) {
        if (typeof(triggerEvent) === "undefined") {
            triggerEvent = true;
        }

        this.showBasketLoading(triggerEvent);

        // Perform request
        var request = $.ajax(window.controller.hmcheckout + '/sidebar-cart');

        // Update cart
        request.done( function(response) {
            this.hideBasketLoading(false);
            $('.mini-cart .modal-content').html(response);
            Postal.channel('checkout').publish('sidebar-basket:loaded');
        }.bind(this));

        request.always(function() {
            this.hideBasketLoading(false);
        }.bind(this));
    },

    /**
     * Handles the click event for removing an item from the cart
     * @param {object} event The click event from the cart
     */
    handleRemoveFromBasketClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget);
        var listItem = target.parents('.mini-cart-listing-item');
        var productId = $(event.currentTarget).data('id');

        $('[data-id="' + productId + '"]').addClass('is-removed');

        // Remove listitem
        listItem.addClass('is-removed');

        this.showBasketLoading();

        // Perform request
        var request = $.ajax(window.controller.hmcheckout + '/delete-article', {
            data    : {sDelete: productId},
            method  : 'POST',
            dataType: 'json',
        });

        request.done(function(response) {
            Postal.channel('checkout:cart').publish('article:removed', {
                response: response
            });
        }.bind(this));

        request.always(function() {
            this.hideBasketLoading();
        }.bind(this));
    },

    /**
     * Handles the click event on a payment/shipping method
     * @param {Event} event The click event
     */
    handlePaymentShippingItemClick: function(event) {
        var $target = $(event.currentTarget);

        // Prevent default if label ist clicked
        if ($(event.target).is('label')) {
            event.preventDefault();
        }

        if ($target.find('input[type="radio"]').length > 0) {
            $target.find('input[type="radio"]').prop('checked', true).trigger('change');
        }
    },

    /**
     * Handles the change event for payment/shipping changes in co process
     * @param {object} event The change event
     */
    handlePaymentShippingChange: function(event) {
        var curTarget = $(event.currentTarget),
            form = curTarget.parents('form'),
            action = form.attr('action'),
            data = form.serialize() + '&isXHR=1';

        // Add loading state
        form.attr('data-state', 'loading');
        this.common.showMainLoader(true);

        var request = $.ajax({
            type: "POST",
            url: action,
            data: data,
        });

        request.done(function(response) {
            $(this.ui.paymentShippingContainer).html(response);
        }.bind(this));

        request.always(function() {
            form.attr('data-state', 'idle');
            this.common.hideMainLoader();
        }.bind(this));
    },

    /**
     * Handles the change event for an cart item
     * We will set the state via data attr
     * @param {object} event The change event
     */
    handleCartItemQuantityChange: function(event) {
        var curTarget = $(event.currentTarget),
            value = parseInt(curTarget.val()),
            orgValue = parseInt(curTarget.data('value'));

        // Set changed state
        curTarget.data('changed', value !== orgValue);
    },

    /**
     * Handles the submit event, for an cart item
     * We will commit the parent form, when the value was changed
     * @param {object} event The blur event of the input field
     */
    handleCartItemQuantityChangeSubmit: function(event) {
        var curTarget = $(event.currentTarget),
            form = curTarget.parents('form');

        // Submit form
        if (curTarget.data('changed') && curTarget.data('changed') === true) {
            form.submit();
        }
    },

    /**
     * Handles the click event, when the remove button of a cart item is clicked
     * @param {object} event The click event
     */
    handleCartItemRemoveClick: function(event) {
        this.common.showMainLoader(true);
    },
};

module.exports = SpecsCheckout;