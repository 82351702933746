var Postal = require('vendor/postal-1.0.7');

/**
 * Static Helper class to provide common functionality
 * @constructor
 * @author Stefan Rueschenberg <sr@humans.am>
 * @package Specs
 */
var SpecsCommon = {
    /**
     * jQuery Reference for window
     * @type {null}|{object}
     */
    window: null,

    /**
     * jQuery Reference for document
     * @type {null}|{object}
     */
    document: null,

    /**
     * jQuery Reference for body
     * @type {null}|{object}
     */
    body: null,

    /**
     * jQuery Reference for html
     * @type {null}|{object}
     */
    html: null,

    /**
     * Initial state for history api
     * @type {Object}
     */
    initialState: null,

    /**
     * Array with classes, that should be remove when adding a new page
     * @type {Array}
     */
    genericClasses: [
        'is-ajax-request',
        'is-direct-request',
        'has-search',
    ],

    /**
     * Object, storing scrollpositions
     * @type {object}
     */
    scrollPositions: {},

    /**
     * Initializes the common component
     */
    init: function() {
        this.getWindow().on('popstate', this.handlePopState.bind(this));
    },

    /**
     * Returns the specs common instance
     * @returns {SpecsCommon}
     */
    getInstance: function() {
        return this;
    },

    /**
     * Returns the jQuery representation of the window
     * @returns {object}
     */
    getWindow: function() {
        if (this.window === null) {
            this.window = $(window);
        }

        return this.window;
    },

    /**
     * Returns the jQuery representation of the document
     * @returns {object}
     */
    getDocument: function() {
        if (this.document === null) {
            this.document = $(window.document);
        }

        return this.document;
    },

    /**
     * Returns the jQuery representation of the body element
     * @returns {object}
     */
    getBody: function() {
        if (this.body === null) {
            this.body = $('body');
        }

        return this.body;
    },

    /**
     * Returns the jQuery representation of the html element
     * @returns {object}
     */
    getHtml: function() {
        if (this.html === null) {
            this.html = $('html');
        }

        return this.html;
    },

    /**
     * Fetches content via ajax request
     * @param {string}  url     The URL to call
     * @param {object=} options Additional options to pass
     * @return {object} jQuery XHR object
     */
    fetchContent: function(url, options) {
        options = options || {};

        var targetContainer = options.target || '#ajax-content';
        var scrollTop       = options.scrollTop || false;
        var scrollPosition  = options.scrollPosition || -1;

        // Add loader
        this.showMainLoader();

        // Define proper url
        url = this.getProperUrl(url);

        var request = $.ajax(url, {
            data: options.data || {},
            method: (options.method || 'GET').toUpperCase(),

            success: function(response, textStatus, jqXhr) {
                var responseContainer = $(targetContainer);

                // Update content
                responseContainer.html(response);

                // Find current ajax container
                var ajaxContainer = responseContainer.find('.ajax-container');
                if (ajaxContainer.data('title')) {
                    document.title = ajaxContainer.data('title');
                }

                // Remove generic classes
                this.getBody().removeClass(this.genericClasses.join(' '));

                // Add body class from ajax request
                if (ajaxContainer.data('bodyclass') && ajaxContainer.data('bodyclass').trim().length > 0) {
                    var additionalBodyClass = ajaxContainer.data('bodyclass').trim().split(' ');
                    if (additionalBodyClass.length) {
                        additionalBodyClass.forEach(function(bodyClass) {
                            this.getBody().addClass(bodyClass);
                        }.bind(this));
                    }
                }

                // Get scroll URL for a proper scrollposition
                var scrollUrl = url.replace(/\/+$/, '');

                // Scroll to anchor
                if (url.search(/#/) !== -1) {
                    var target = url.split('#')[1];
                    if (responseContainer.find('#' + target).length > 0) {
                        var scrollPos = responseContainer.find('#' + target).offset().top - $('.main-head').height() * 2;
                        $('html, body').stop().animate({scrollTop: scrollPos}, 500);
                    }
                } else if (this.scrollPositions.hasOwnProperty(scrollUrl)) {
                    this.getDocument().scrollTop(this.scrollPositions[scrollUrl]);
                    delete this.scrollPositions[scrollUrl];
                } else if (scrollTop && this.getDocument().scrollTop() > 0) { // Scroll to top?
                    //$('html, body').stop().animate({scrollTop: 0}, 400); // Animate it?
                    this.getDocument().scrollTop(0);
                } else if (scrollPosition > 0) {
                    this.getDocument().scrollTop(scrollPosition);
                    //$('html, body').stop().animate({scrollTop: scrollPosition}, 500);
                }

                // Call success
                if (options.successCallback && typeof options.successCallback === 'function') {
                    options.successCallback.apply(this, arguments);
                }

                // Publish fetch done event
                Postal.channel('common').publish('fetch-content:success', {response: response});
            }.bind(this),

            error: function(jqXhr, textStatus, errorThrown) {
                if (options.errorCallback && typeof options.errorCallback === 'function') {
                    options.errorCallback.apply(this, arguments);
                }

                // Publish fetch errorr event
                Postal.channel('common').publish('fetch-content:error', {
                    textStatus : textStatus,
                    errorThrown: errorThrown,
                });
            }.bind(this),
        });

        // Remove loader
        request.always(function() {
            this.hideMainLoader();
        }.bind(this));

        return request;
    },

    /**
     * Adds a new state to the history API
     * @param {string}  url   URL to add
     * @param {object=} state State object
     * @param {string=} title Browser title to set
     */
    pushState: function(url, state, title) {
        if (window.history && window.history.pushState) {
            // Define proper url
            url = this.getProperUrl(url);

            // Initialstate set?
            if (!this.initialState) {
                this.initialState = {
                    content: $('#main-content').html(),
                    title: document.title,
                };
            }

            // Confirm params
            state = state || {};
            title = title || document.title;

            // Extend data
            state.url = url;
            state.title = document.title;

            history.pushState(state, title, url);

            // Publish event
            Postal.channel('common').publish('pushstate', {state: state});
        }
    },

    /**
     * Replaces the current push state with the new state
     * @param {string}  url   URL to add
     * @param {object=} state State object
     * @param {string=} title Browser title to set
     */
    replaceState: function(url, state, title) {
        if (window.history && window.history.replaceState) {
            // Define proper url
            url = this.getProperUrl(url);

            // Confirm params
            state = state || {};
            title = title || document.title;

            // Extend data
            state.url   = url;
            state.title = document.title;

            history.replaceState(state, title, url);
        }
    },

    /**
     * Returns a proper URL
     * @param {string} url The input URL
     * @returns {string} The proper URL
     */
    getProperUrl: function(url) {
        url = url.replace(window.location.origin, '');

        return url;
    },

    /**
     * Handles the popstate event from history api
     * @param event
     */
    handlePopState: function(event) {
        var oEvent = event.originalEvent || event;
        if (!oEvent.state && this.getBody().hasClass('is-loaded')) {
            this.fetchContent(this.getProperUrl(window.location.href));
        }
    },

    /**
     * Shows the main ajax loader
     * @param {boolean=} slim (Optional) show slim loader
     */
    showMainLoader: function(slim) {
        slim = slim || false;

        if (slim) {
            this.getBody().addClass('is-loading-ajax-slim');
        } else {
            this.getBody().addClass('is-loading-ajax');
        }

    },

    /**
     * Hides the main ajax loader
     */
    hideMainLoader: function() {
        this.getBody().removeClass('is-loading-ajax is-loading-ajax-slim');
    },


    /**
     * Returns the rem value for the given pixel value
     * @param {number} pxSize The pixel value
     * @return {number} The rem value
     */
    pxToRem: function(pxSize) {
        var fontSize = Number(this.html.css('fontSize').replace('px', ''));

        return Math.round(pxSize / fontSize * 10) / 10;
    },
};

module.exports = SpecsCommon;