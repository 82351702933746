var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Customer
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsCustomer = function(options) {
    this.init(options);
};

SpecsCustomer.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * UI ref classes
     */
    ui: {
        diffShippingAddressCheckbox: '#shipping-address-toggle',
        skipLoginToggle: '#skip-login-toggle',
        shippingAddressFormGroup: '#shipping-address-group',
        registerPasswordFields: '#register-password-fields',
        billingCountryField: 'select[data-type="country-billing"]',
        shippingCountryField: 'select[data-type="country-shipping"]',
        shippingCountryLabel: 'input[data-type="country-shipping-label"]',
        shippingCountryValue: 'input[data-type="country-shipping-value"]',
        phoneGridCell: '.form-grid-cell--phone',
        billingZipcodeField: '#billing-zipcode',
        shippingZipcodeField: '#shipping-zipcode',
    },

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @return {SpecsFilter}
     */
    bindUiEvents: function() {
        this.$body.on('change', this.ui.skipLoginToggle, this.handleSkipLoginToggle.bind(this));
        this.$body.on('change', this.ui.diffShippingAddressCheckbox, this.handleShippingAddressToggle.bind(this));
        this.$body.on('change', this.ui.billingCountryField, this.handleBillingCountryChange.bind(this));

        // Handle zip code input
        this.$body.on('input', this.ui.billingZipcodeField, function(event) {
            this.handleZipcodeInput($(event.currentTarget), $(this.ui.billingCountryField));
        }.bind(this));

        this.$body.on('input', this.ui.shippingZipcodeField, function(event) {
            this.handleZipcodeInput($(event.currentTarget), $(this.ui.shippingCountryField));
        }.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {SpecsFilter}
     */
    bindCustomEvents: function() {


        return this;
    },

    /**
     * Updates form fields inside of an container to required/not required
     * @param {object} container The container with the fields
     * @param {object} required  Set required or remove required
     */
    toggleRequiredFields: function(container, required) {
        var requiredFields = container.find([
            'input.is-required',
            'select.is-required',
            'textares.is-required',
        ].join(','));

        requiredFields.each(function(index, field) {
            field = $(field);

            if (!required) {
                field.removeAttr('required aria-required');
            } else {
                field.attr({
                    'required': 'required',
                    'aria-required': 'true'
                });
            }
        });
    },

    /**
     * Handles the toggle of the skip login. It will show or hide password fields
     * @@param {object} event The change event of the checkbox
     */
    handleSkipLoginToggle: function(event) {
        var curTarget = $(event.currentTarget),
            targetContainer = $(this.ui.registerPasswordFields);

        var hiddenState = curTarget.prop('checked') === true;

        // Show/Hide fields
        targetContainer.attr('data-state', hiddenState ? 'hidden' : 'visible');

        // Toggle required state
        this.toggleRequiredFields(targetContainer, !hiddenState);
    },

    /**
     * Handles the toggle of the shipping address
     * @param {object} event The change event of the checkbox
     */
    handleShippingAddressToggle: function(event) {
        var curTarget = $(event.currentTarget),
            targetContainer = $(this.ui.shippingAddressFormGroup);

        var visibleState = curTarget.prop('checked') === true;

        // Show/Hide fields
        targetContainer.attr('data-state', visibleState ? 'visible' : 'hidden');

        // Scroll to position
        if (visibleState) {
            var scrollPos = targetContainer.offset().top - $('.main-head').height() * 2;
            $('html, body').stop().animate({scrollTop: scrollPos}, 400);
        }

        // Toggle required state
        this.toggleRequiredFields(targetContainer, visibleState);
    },

    /**
     * Handles the change event, when the billing country is changed
     * We will set, the shipping country
     * @param {object} event The change event
     */
    handleBillingCountryChange: function(event) {
        var curTarget = $(event.currentTarget),
            value = Number(curTarget.val()),
            label = curTarget.find('option:selected').text().trim();

        if ($(this.ui.shippingCountryValue).length > 0) {
            $(this.ui.shippingCountryValue).val(value);
        }

        if ($(this.ui.shippingCountryLabel).length > 0) {
            $(this.ui.shippingCountryLabel).val(label);
        }

        // Update phone required field
        // var $phoneLabel = $(this.ui.phoneGridCell).find('label');
        // var $phoneInput = $(this.ui.phoneGridCell).find('input');
        //
        // if (value !== 2) {
        //     $phoneLabel.removeClass('is-optional').addClass('is-required');
        //     $phoneInput.attr({
        //         'required': 'required',
        //         'aria-required': 'true'
        //     });
        // } else {
        //     $phoneLabel.removeClass('is-required').addClass('is-optional');
        //     $phoneInput.removeAttr('required aria-required');
        // }
    },

    /**
     * Handles the input of the zip code field. We will adjust the country for Spain and Portugal
     * @param {jQuery} $inputField The related input field
     * @param {jQuery} $countryField The related country field
     */
    handleZipcodeInput: function($inputField, $countryField) {
        var selectedCountryIso = $countryField.find('option:selected').data('iso');

        if (['ES', 'ESX', 'PT', 'PTX'].indexOf(selectedCountryIso) === -1) {
            return;
        }

        var value = $inputField.val();

        // Handle spain cases
        if (selectedCountryIso === 'ES' || selectedCountryIso === 'ESX') {
            var esId = $countryField.find('option[data-iso="ES"]').attr('value');
            var esxId = $countryField.find('option[data-iso="ESX"]').attr('value');

            if (/^(35|38|51|52)/.test(value)) {
                $countryField.val(esxId).trigger('change');
            } else {
                $countryField.val(esId).trigger('change');
            }
        } else if (selectedCountryIso === 'PT' || selectedCountryIso === 'PTX') {
            var ptId = $countryField.find('option[data-iso="PT"]').attr('value');
            var ptxId = $countryField.find('option[data-iso="PTX"]').attr('value');

            if (/^9/.test(value)) {
                $countryField.val(ptxId).trigger('change');
            } else {
                $countryField.val(ptId).trigger('change');
            }
        }
    },
};

module.exports = SpecsCustomer;