var SpecsCommon   = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal        = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Site
 * This class will handle most of the frontend actions
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsAuthentication = function(options) {
    this.init(options);
};

SpecsAuthentication.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * Current scroll top position
     * @type {Number}
     */
    scrollTop: 0,

    /**
     * Last scroll top position
     * @type {Number}
     */
    lastScrollTop: 0,

    /**
     * Indicates if the UI is rendering
     * @type {Boolean}
     */
    isRendering: false,

    /**
     * Indicates, if the user has scrolled
     * @type {Boolean}
     */
    hasUserScrolled: false,

    loggedIn: false,

    /**
     * UI ref classes
     */
    ui: {
        mainNavLoginWrapper: '.main-nav-login-wrapper',
        showLoginFormButton: '[data-action="show-login-form"]',
        showForgotPasswordFormButton: '[data-action="show-forgot-password-form"]',
        forgotPasswordFormArea: '.forgot-password-form-area',
        loginFormArea: '.login-form-area',
        signupFormArea: '.signup-form-area',
        loginForm: 'form[name="login-form"]',
        signupForm: 'form[name="signup-form"]',
        forgotPasswordForm: 'form[name="forgot-password-form"]',
        logoutButton: '.logout-button a',
    },

    /**
     * Holds the jQuery references for a selector
     * @type {Object}
     */
    references: {},

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind custom events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @returns {SpecsAuthentication}
     */
    bindUiEvents: function() {
        this.$body.on('click', this.ui.showLoginFormButton, this.showLoginForm.bind(this));
        this.$body.on('click', this.ui.showForgotPasswordFormButton, this.showForgotPasswordForm.bind(this));
        this.$body.on('submit', this.ui.loginForm, this.handleLoginSubmit.bind(this));
        this.$body.on('submit', this.ui.forgotPasswordForm, this.handleForgotPasswordSubmit.bind(this));
        this.$body.on('click', this.ui.logoutButton, this.handleLogoutClick.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @returns {SpecsAuthentication}
     */
    bindCustomEvents: function() {
        return this;
    },

    showLoading: function(form) {
        form.addClass("is-loading");
        form.find('.input-error').removeClass('input-error');
        form.find('.has-error').removeClass('has-error');
        form.find(".error-message").html("");
        form.find(".success").remove();
    },

    hideLoading: function(form) {
        form.removeClass("is-loading");
    },

    showSuccess: function(form, messages) {
        form.slideUp();

        if (typeof messages === 'string') {
            messages = [messages];
        }

        $(messages).each(function(index, message) {
            form.after('<p class="success">' + message + '</p>');
        });
    },

    showError: function(form, messages) {
        var messageField = form.find('.error-message');
        messageField.html('');

        if (typeof messages === 'string') {
            messages = [messages];
        }

        $(messages).each(function(index, message) {
            messageField.append('<p>' + message + '</p>');
        });
    },

    showUnknownError: function(form) {
        this.showError(form, [{msg: "An unknown error occurred.", element: null}]);
    },

    /**
     * Shows the forgot password form
     */
    showForgotPasswordForm: function(event) {
        event.preventDefault();
        this.$body.find(this.ui.mainNavLoginWrapper).attr('data-action', 'forgot-password');
        $('.forgot-password-form-area input[name="email"]').focus();
    },

    /**
     * Shows the login form
     */
    showLoginForm: function(event) {
        event.preventDefault();
        this.$body.find(this.ui.mainNavLoginWrapper).attr('data-action', 'login');
        $('.login-form-area input[name="email"]').focus();
    },

    /**
     * Handles the submit event of the login form
     * @param {object} event The submit event of the login form
     */
    handleLoginSubmit: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget);

        this.processAjaxFormRequest(target, target.attr('action'), function(form, response) {
            setTimeout(function() {
                window.location.reload();
            }, 1000);
        }, function(form, response) {
            form.find('input[type="text"], input[type="password"]').addClass('has-error');
        });
    },

    /**
     * Handles the click on the logout button and performs a reload
     * @param {object} event The click event
     */
    handleLogoutClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = target.attr('href');

        // Add loader
        this.common.showMainLoader();

        var request = $.ajax(href, {
            dataType: 'json',
        });

        // Logout was successful
        request.done(function(response) {
            window.location.reload();
        }.bind(this));

        // Remove loader
        request.always(function() {
            this.common.hideMainLoader();
        }.bind(this));
    },

    /**
     * Handles the submit event of the forgot password form
     * @param {object} event The submit event
     */
    handleForgotPasswordSubmit: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget);

        this.processAjaxFormRequest(target, target.attr('action'), function(form, response) {
            setTimeout(function() {
                this.$body.find(this.ui.mainNavLoginWrapper).attr('data-action', 'login');
                $('.login-form-area input[name="email"]').focus();
            }.bind(this), 1000);

            setTimeout(function() {
                target.slideDown();
                target.find('.success').remove();
            }.bind(this), 2000);
        }, function(form, response) {
            form.find('input[type="text"]').addClass('has-error');
        });
    },

    /**
     * Processes an ajax form
     * @param {object}    form            The jquery reference of the form
     * @param {string}    url             The URL for the ajax request
     * @param {function=} successCallback (Optional) Callback, when request was successful
     * @param {function=} errorCallback   (Optional) Callback, when request caused an error
     */
    processAjaxFormRequest: function(form, url, successCallback, errorCallback) {
        this.showLoading(form);

        var request = $.ajax(url, {
            data    : form.serialize(),
            method  : 'POST',
            dataType: 'json',
        });

        // Request successful
        request.done(function(response) {
            if (response.success) {
                this.showSuccess(form, response.messages);

                if (successCallback && typeof successCallback === 'function') {
                    successCallback.call(this, form, response);
                }
            } else {
                this.showError(form, response.messages);

                if (errorCallback && typeof errorCallback === 'function') {
                    errorCallback.call(this, form, response);
                }
            }
        }.bind(this));

        // Request failed
        request.fail(function() {
            this.showUnknownError(form);
        }.bind(this));

        // Hide loading
        request.always(function() {
            this.hideLoading(form);
        }.bind(this))
    }
};

module.exports = SpecsAuthentication;