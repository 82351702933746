var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsTracking = function(options) {
    this.init(options);
}

SpecsTracking.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * UI ref classes
     */
    ui: {
        gtmTrackingTag: '.gtm-tracking-tag',
        fbTrackingTag: '.fb-tracking-tag',

        paymentShippingForm: '#payment-shipping-form',
        selectedPaymentMethod: '.methods-panel--payment input[type="radio"]:checked',
        selectedShippingMethod: '.methods-panel--shipping input[type="radio"]:checked',

        confirmCartSummary: '.confirm-cart-summary',
        confirmCartSummaryItem: '.confirm-cart-summary .cart-item',
    },

    /**
     * Debug mode?
     * @type {boolean}
     */
    debug: false,

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @return {SpecsTracking}
     */
    bindUiEvents: function() {
        this.$body.ready(this.handleReady.bind(this));
        this.$body.on('submit', this.ui.paymentShippingForm, this.handlePaymentShippingFormSubmit.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {SpecsTracking}
     */
    bindCustomEvents: function() {
        Postal.channel('site').subscribe('search:triggered', this.handleSearchTriggerd.bind(this));
        Postal.channel('checkout:cart').subscribe('article:added', this.handleArticleAdded.bind(this));

        Postal.channel('common').subscribe('fetch-content:success', function () {
            this.trackGtags();
            this.trackFbTags();
        }.bind(this));

        return this;
    },

    /**
     * Handles the ready event
     */
    handleReady: function() {
        // Track gtags
        this.trackGtags();
        this.trackFbTags();

        var $confirmCartSummary = $(this.ui.confirmCartSummary);
        var $confirmCartSummaryItems = $(this.ui.confirmCartSummaryItem);

        if ($confirmCartSummary.length > 0 && $confirmCartSummaryItems.length > 0) {
            var products = [];
            $confirmCartSummaryItems.each(function(idx, item) {
                var $item = $(item);

                products.push({
                    'id': $item.data('ordernumber'),
                    'quantity': Number($item.data('quantity')),
                    'item_price': Number($item.data('price')),
                })
            });

            this.fbTrack('Purchase', {
                content_type: 'product',
                value: Number($confirmCartSummary.data('total')),
                currency: $confirmCartSummary.data('currency'),
                contents: products,
            });
        }
    },

    /**
     * Tracks the tracking tags on ready or if content was changed via ajax
     */
    trackGtags: function() {
        $(this.ui.gtmTrackingTag).each(function(idx, tag) {
            this.trackGtag($(tag));
        }.bind(this));
    },

    /**
     * Tracks the tracking tags on ready or if content was changed via ajax
     */
    trackFbTags: function() {
        $(this.ui.fbTrackingTag).each(function(idx, tag) {
            this.trackFbTag($(tag));
        }.bind(this));
    },

    /**
     * Handles the search triggered event
     * @param {object} payload The related payload
     */
    handleSearchTriggerd: function(payload) {
        if (payload.keyword) {
            this.fbTrack('Search', {search_string: payload.keyword});
            this.gtagTrack('search', {search_term: payload.keyword});
        }
    },

    /**
     * Triggered, when the payment shipping for is submitted
     * @param {object} event The submit event
     */
    handlePaymentShippingFormSubmit: function(event) {
        this.fbTrack('AddPaymentInfo');

        // Track payment method selection
        if ($(this.ui.selectedPaymentMethod).length > 0) {
            this.gtagTrack('set_checkout_option', {
                checkout_step: 2,
                checkout_option: 'payment method',
                value: $(this.ui.selectedPaymentMethod).next().html().trim(),
            });
        }

        // Track shipping method selection
        if ($(this.ui.selectedShippingMethod).length > 0) {
            this.gtagTrack('set_checkout_option', {
                checkout_step: 2,
                checkout_option: 'shipping method',
                value: $(this.ui.selectedShippingMethod).next().html().trim(),
            });
        }
    },

    /**
     * Triggered, when an article was added to cart
     * @param {object} payload The related payload
     */
    handleArticleAdded: function(payload) {
        // FB track
        this.fbTrack('AddToCart', {
            content_name: payload.response.name,
            content_category: payload.response.category,
            content_type: 'product',
            contents: {
                id: payload.response.ordernumber,
                quantity: payload.response.quantity,
                item_price: payload.response.price,
            },
            value: payload.response.sum,
            currency: payload.response.currency,
        });

        // Gtag track
        this.gtagTrack('add_to_cart', {
            items: [
                {
                    id: payload.response.ordernumber,
                    name: payload.response.name,
                    brand: payload.response.brand,
                    category: payload.response.category,
                    variant: payload.response.variant,
                    quantity: payload.response.quantity,
                    price: payload.response.price,
                }
            ]
        });
    },

    /**
     * Tracks a facebook Pixel event
     * @param {string}  type    The event name that should be tracked
     * @param {object=} payload (Optional) The payload for the event
     */
    fbTrack: function(type, payload) {
        if (this.isFbPixelActive()) {
            if (payload) {
                if (this.debug) {
                    this.logFb(type, payload);
                }

                fbq('track', type, payload);
            } else {
                if (this.debug) {
                    this.logFb(type);
                }

                fbq('track', type);
            }
        }
    },

    /**
     * Indicates if the facebook pixel is active
     * @return {boolean} True, if FB pixel is active
     */
    isFbPixelActive: function() {
        return typeof window.fbq !== 'undefined';
    },

    /**
     * Tracks a single GTM tracking tag
     * @param {jQuery} $tag The tag to track
     */
    trackGtag: function($tag) {
        if (!$tag.data('tracked')) {
            var type = $tag.data('type');
            var payload;

            // Try to get payload from JSON content
            try {
                payload = JSON.parse($tag.html().trim());
            } catch (e) {
                payload = null;
            }

            if (payload) {
                switch (type) {
                    // Product detail impression
                    case 'productListing':
                        //this.trackListingPage(payload);
                        break;

                    // Product detail impression
                    case 'view_item':
                        this.gtagTrackViewItem(type, payload);
                        break;

                    // Begin checkout
                    case 'begin_checkout':
                        this.gtagTrackBeginCheckout(type, payload);
                        break;

                    // Purchase
                    case 'purchase':
                        this.gtagTrackPurchase(type, payload);
                        break;
                }

                // Mark as tracked
                $tag.data('tracked', true);
            }
        }
    },

    /**
     * Tracks a single FB tracking tag
     * @param {jQuery} $tag The tag to track
     */
    trackFbTag: function($tag) {
        if (!$tag.data('tracked')) {
            var type = $tag.data('type');
            var payload;

            // Try to get payload from JSON content
            try {
                payload = JSON.parse($tag.html().trim());
            } catch (e) {
                payload = null;
            }

            if (payload) {
                switch (type) {
                    // Page View
                    case 'PageView':
                        this.fbTrack(type);
                        break;

                    // Product detail impression
                    case 'ViewContent':
                        this.fbTrack(type, payload);
                        break;
                }

                // Mark as tracked
                $tag.data('tracked', true);
            }
        }
    },

    /**
     * Tracks a product detail impression for the given data
     * @param {string}  eventName Name of the event to track
     * @param {object}  payload   The payload for tracking
     */
    gtagTrackViewItem: function(eventName, payload) {
        this.gtagTrack(eventName, {
            items: [{
                id: payload.id,
                name: payload.name,
                brand: payload.brand,
                category: payload.category,
                variant: payload.variant,
                quantity: payload.quantity || 1,
                price: payload.price,
            }]
        });
    },

    /**
     * Tracks the begin checkout event
     * @param {string}  eventName Name of the event to track
     * @param {object}  payload   The payload for tracking
     */
    gtagTrackBeginCheckout: function(eventName, payload) {
        this.gtagTrack(eventName, {
            items: payload.products,
            coupon: '',
        });
    },

    /**
     * Tracks the purchase event
     * @param {string}  eventName Name of the event to track
     * @param {object}  payload   The payload for tracking
     */
    gtagTrackPurchase: function(eventName, payload) {
        this.gtagTrack(eventName, payload.transaction);
    },

    /**
     * Tracks the gtag event for the given params
     * @param {string} eventName Name of the event to track
     * @param {object} payload   The payload of the event
     */
    gtagTrack: function(eventName, payload) {
        if (this.isGtagActive()) {
            if (this.debug) {
                this.logGtag(eventName, payload);
            }

            window.gtag('event', eventName, payload);
        }
    },

    /**
     * Indicates if the gtag is active
     * @return {boolean} True, if gtag is active
     */
    isGtagActive: function() {
        return typeof window.gtag !== 'undefined';
    },

    logFb: function() {
        if (arguments.length === 1) {
            console.log('%cFB' + '%c' + arguments[0], this.logStyle('#4080FF'), this.logStyle('#282828'));
        } else if (arguments.length === 2) {
            console.log('%cFB' + '%c' + arguments[0], this.logStyle('#4080FF'), this.logStyle('#282828'), arguments[1]);
        }
    },
    logGtag: function() {
        if (arguments.length === 1) {
            console.log('%cGA' + '%c' + arguments[0], this.logStyle('#33A853'), this.logStyle('#282828'));
        } else if (arguments.length === 2) {
            console.log('%cGA' + '%c' + arguments[0], this.logStyle('#33A853'), this.logStyle('#282828'), arguments[1]);
        }
    },

    /**
     * Internal method for log style
     * @param {string} background Background color
     * @param {string} text Text color
     * @return {string} The console style
     */
    logStyle: function(background, text) {
        text = text || '#fff';

        if (!/^#/.test(background)) {
            background = '#' + background;
        }

        if (!/^#/.test(text)) {
            text = '#' + text;
        }

        return 'background: ' + background + '; color: ' + text + '; font-weight: bold; display: inline-block; border-radius: 4px; padding: 3px 6px; margin-right: 6px;';
    },
};

module.exports = SpecsTracking;