var SpecsCommon   = require('specs/common');
var ImportOptions = require('helper/import-options');
var parseQuery    = require('helper/parse-query');
var Postal        = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsFilter = function(options) {
    this.init(options);
};

SpecsFilter.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @return {SpecsFilter}
     */
    bindUiEvents: function() {
        this.$body.on('click', '.filter li label', this.handleFilterLabelClick.bind(this));
        this.$body.on('click', '.selected-filter button[data-action="remove-filter-value"]', this.removeFilterValue.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {SpecsFilter}
     */
    bindCustomEvents: function() {
        this.$win.on('popstate', this.handleStateChange.bind(this));
        Postal.channel('common').subscribe('pushstate', this.handleStateChange.bind(this));

        return this;
    },

    /**
     * Handles the event, when a filter label is clicked
     * @param {object} event The click event
     */
    handleFilterLabelClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget);

        if (target.hasClass('chosen')) {
            this.removeFilterValue(event);
        } else {
            this.addFilterValue(event);
        }
    },

    /**
     * Removes a value from the filter
     * @param {object} event The click event from the filter item
     */
    removeFilterValue: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget);
        var param   = target.data('id'),
            variable = target.data('var');

        var vars = parseQuery(window.location.href);

        if (vars.hasOwnProperty(variable)) {
            var filters = vars[variable].split('|');
            var notInclude = filters.indexOf(String(param));
            var newFilters = [];

            for (var i = 0; i < filters.length; i++) {
                if (i != notInclude) {
                    newFilters.push(filters[i]);
                }
            }

            vars[variable] = newFilters.join('|');

            if (!vars[variable]) {
                delete vars[variable];
            }
        }

        var href = this.joinQueryParams(vars);

        this.fetchFilterContent(href);
    },


    /**
     * Adds a filter value
     * @param {object} event The click event from the filter item
     */
    addFilterValue: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href   = target.attr('href');

        var param = target.attr('for').split('__')[1];

        if (!href) {
            var vars = parseQuery(window.location.href),
                value = target.prev().find('input[type="checkbox"]').val();

            if (param == 'filter') {
                if (vars.hasOwnProperty('filter')) {
                    vars.filter += '|' + value;
                } else {
                    vars.filter = value;
                }
            } else if (param == 's') {
                if (vars.hasOwnProperty('s')) {
                    vars.s += '|' + value;
                } else {
                    vars.s = value;
                }
            }

            href = this.joinQueryParams(vars);
        }

        this.fetchFilterContent(href);
    },

    /**
     * Fetch the content for the selected filter
     */
    fetchFilterContent: function(href) {

        // Push state
        this.common.pushState(href, {
            type: 'filter',
        });
    },

    /**
     * Handles the state change event
     * @param {object} event The state change event
     */
    handleStateChange: function(event) {
        event = event.originalEvent || event;

        var stateType = null;

        if (event.state) {
            if (event.state.type) {
                stateType = event.state.type;
            }
        }

        // Type is filter? -> Fetch content
        if (stateType === 'filter') {
            // Fetch the content
            this.common.fetchContent(event.state.url, {
                scrollTop: true,
            });
        }
    },

    /**
     * Joins query params
     * @param {object} vars Vars to join
     * @returns {string} The query param string
     */
    joinQueryParams: function(vars) {
        var href = vars['~'];
        delete vars['~'];

        var queryString = '';
        for (var k in vars) {
            if (!queryString) {
                queryString += '?';
            } else {
                queryString += '&';
            }

            queryString += k + '=' + vars[k];
        }

        href += queryString;

        return href;
    },
};

module.exports = SpecsFilter;