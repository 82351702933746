var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');
var Cookies = require('vendor/js.cookie');

/**
 * Definition of the Specs Site
 * This class will handle most of the frontend actions
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsSite = function(options) {
    this.init(options);
}

SpecsSite.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * Current scroll top position
     * @type {Number}
     */
    scrollTop: 0,

    /**
     * Last scroll top position
     * @type {Number}
     */
    lastScrollTop: 0,

    scrollbarWidth: null,

    /**
     * Indicates if the UI is rendering
     * @type {Boolean}
     */
    isRendering: false,

    /**
     * Indicates, if the user has scrolled
     * @type {Boolean}
     */
    hasUserScrolled: false,

    /**
     * UI ref classes
     */
    ui: {
        navIcon: '#navicon',
        cartTrigger: '.cart-trigger',
        searchTrigger: '.search-trigger',
        loginTrigger: '.login-trigger, button[data-action="show-login"]',
        logoutTrigger: '.logout-trigger',
        accountTrigger: '.account-trigger',
        localisationTrigger: '.js-localisation-trigger',
        filterTrigger: '.filter-head',
        filterDismiss: '#side-cover-filter',
        sideCover: '#side-cover',
        navCover: '#nav-cover',
        navMainModalClose: '.nav-main .close-modal',
        miniCartModalClose: '.mini-cart .close-modal',
        loginBoxModalClose: '.login-box .close-modal',
        searchField: '.header-search input[type="search"]',
        searchForm: '.header-search form',
        searchDismiss: '.btn[data-action="dismiss-search"]',
        beforeFooter: '.before-footer',
        myAccountButton: '.my-account-button',
        loginButton: '.login-button',
        logoutButton: '.logout-button',
        logoLink: '.logo a',
        mainNavLinks: '.nav-head--main a:not([data-modal])',
        sideNavLinks: '.nav-main-sec a:not([data-modal]), .nav-main-third .third a:not([data-modal])',
        footerNavLinks: '.footer-menu a',
        productDetailLinks: '.nav-product a',
        contentAjaxLink: 'a[rel="ajax-request"]',
        defaultLoadingForm: 'form[data-loader="default-loader"]',
        mainNavDropdownCell: '.nav-head--main .has-dropdown',
        sideMainNavLink: '.nav-main-main > ul > li > a:not([data-modal])',
        accordionLink: '.accordion a',
        sideNavAccordion: '.nav-main-main .accordion',
        gaOptoutLink: 'a[data-action="ga:optout"]',

        // Localisation / Country Selection
        localisationModal: '.modal--localisation',
        localisationModalClose: '.modal--localisation .close-modal',
        frameNotificationModalClose: '.modal--frame-notification .close-modal',
        localisationForm: '.js-localisation-form',
        countrySelectionGroup: '.country-selection-list__group',
        countrySelectionCountry: '.country-selection-list__country',
        countrySelectionSearchField: '.country-selection-search__input',
        countrySelectionCurrent: '.js-country-selection-current',

        // Shipping selector
        shippingSelectorContainer: '.shipping-selector',
        shippingSelectorCountry: '.shipping-selector__country',
        shippingSelectorResult: '.shipping-selector__result',

        // Free shipping Banner
        freeShippingBannerCloseButton: '.js-close-free-shipping-banner',

        // Cookie consent
        cookieConsentContainer: '.cookie-consent',
        cookieConsentSelectChoicesButton: '.js-cookie-consent-confirm-selected',
        cookieConsentSelectAllButton: '.js-cookie-consent-confirm-all',

        // Modal window
        modalWindowTrigger: 'a[data-modal="iframe"]',
        modalWindow: '.modal-window',
        modalWindowIframe: '.modal-window__iframe',
        modalWindowCloseTrigger: '.js-close-modal-window',
    },

    /**
     * Holds the jQuery references for a selector
     * @type {Object}
     */
    references: {},

    /**
     * Current country selection index
     * @type {Number}
     */
    countrySelectionIndex: 0,

    /**
     * Current value for the country selection search
     * @type {string}
     */
    countrySelectionSearchVal: '',

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind custom events
        this.bindUiEvents().bindCustomEvents();

        this.initHacks();
    },

    /**
     * Binds UI events, when ready is fired
     * @returns {SpecsSite}
     */
    bindUiEvents: function() {
        // Bind ready, load, resize and resize
        this.$doc.on('ready', this.handleReady.bind(this));
        this.$win.on('load', this.handleLoad.bind(this));
        this.$win.on('resize', this.handleResize.bind(this));
        this.$doc.on('scroll', this.handleScroll.bind(this));

        // Bind icon trigger
        this.$body.on('click', this.ui.navIcon, this.handleNavIconClick.bind(this));
        this.$body.on('click', this.ui.cartTrigger, this.handleCartClick.bind(this));
        this.$body.on('click', this.ui.searchTrigger, this.handleSearchClick.bind(this));
        this.$body.on('click', this.ui.loginTrigger, this.handleLoginClick.bind(this));
        this.$body.on('click', this.ui.localisationTrigger, this.handleLocalisationClick.bind(this));

        // Modal close
        this.$body.on('click', [
            this.ui.navMainModalClose,
            this.ui.sideCover,
        ].join(','), this.closeAllModals.bind(this));

        this.$body.on('click', [
            this.ui.miniCartModalClose,
            this.ui.loginBoxModalClose,
            this.ui.localisationModalClose,
            this.ui.frameNotificationModalClose,
            this.ui.navCover,
        ].join(','), this.closeModal.bind(this));

        // Filter
        this.$body.on('click', this.ui.filterTrigger, this.handleFilterTrigger.bind(this));
        this.$body.on('click', this.ui.filterDismiss, this.handleFilterDismiss.bind(this));

        // Bind main nav links to trigger via ajax
        var ajaxLinks = [
            this.ui.logoLink,
            this.ui.sideNavLinks,
            this.ui.footerNavLinks,
            this.ui.contentAjaxLink,
            this.ui.productDetailLinks,
            this.ui.accordionLink,
        ];

        // Handle main nav link as ajax link for non touch
        if (!window.browser.has.touch) {
            ajaxLinks.push(this.ui.mainNavLinks);
        }

        this.$body.on('click', ajaxLinks.join(', '), this.handleAjaxLinkClick.bind(this));

        // Default loading form
        this.$body.on('submit', this.ui.defaultLoadingForm, this.handleDefaultLoadingForm.bind(this));

        // Handle main mega dropdown
        if (window.browser.has.touch) {
            this.$body.on('click', this.ui.mainNavLinks, this.handleTouchMainNavClick.bind(this));
            this.$body.on('blur', this.ui.mainNavDropdownCell, this.handleTouchMainNavBlur.bind(this));
            this.$body.on('touchstart', '#ajax-content', function(event) {
                var target = $(event.target);

                // Trigger blur to close navigation
                if (target.parents('.nav-head--main').length === 0) {
                    $(this.ui.mainNavDropdownCell).trigger('blur');
                }
            }.bind(this));
        }

        // Sidenav accordion
        this.$body.on('click', this.ui.sideMainNavLink, this.handleSideNavMainClick.bind(this));

        // GA Optout
        this.$body.on('click', this.ui.gaOptoutLink, function(event) {
            event.preventDefault();

            var target = $(event.currentTarget);

            if (window.gaOptout) {
                window.gaOptout();

                if (target.data('message')) {
                    window.alert(target.data('message'));
                }
            }
        });

        // Localisation / Country Selection
        this.$body.on('submit', this.ui.localisationForm, this.onLocalisationFormSubmit.bind(this));
        this.$body.on('click', this.ui.countrySelectionCountry, this.onCountrySelectionClick.bind(this));
        this.$body.on('input', this.ui.countrySelectionSearchField, this.onCountrySelectionSearchInput.bind(this));
        this.$body.on('change', this.ui.countrySelectionSearchField, this.onCountrySelectionSearchInput.bind(this));
        this.$body.on('keydown', this.ui.countrySelectionSearchField, this.onCountrySelectionSearchKeydown.bind(this));

        // Shipping selector
        this.$body.on('change', this.ui.shippingSelectorCountry, function() {
            this.fetchShippingSelectorResult();
        }.bind(this));

        // Free shipping banner
        this.$body.on('click', this.ui.freeShippingBannerCloseButton, this.handleFreeShippingCloseClick.bind(this));

        // Cookie consent
        this.$body.on('click', this.ui.cookieConsentSelectChoicesButton, this.handleCookkieSelectChoicesClick.bind(this));
        this.$body.on('click', this.ui.cookieConsentSelectAllButton, this.handleCookkieSelectAllClick.bind(this));

        // Modal window
        this.$body.on('click', this.ui.modalWindowTrigger, this.handleModalWindowOpenClick.bind(this));
        this.$body.on('click', this.ui.modalWindowCloseTrigger, this.handleModalWindowCloseClick.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @returns {SpecsSite}
     */
    bindCustomEvents: function() {
        Postal.channel('checkout:cart').subscribe('article:added', function(data) {
            this.showModal('has-mini-cart');
        }.bind(this));

        // Bind postal events
        this.$win.on('popstate', this.handleStateChange.bind(this));
        Postal.channel('common').subscribe('pushstate', this.handleStateChange.bind(this));
        Postal.channel('common').subscribe('fetch-content:success', this.renderAfterAjaxSuccess.bind(this));

        return this;
    },

    initHacks: function() {
        // check for scrollbar-width
        var scrollDiv = document.createElement('div');
        scrollDiv.className = 'scrollbar-measure';
        document.body.appendChild(scrollDiv);
        this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);

        if (this.scrollbarWidth > 10) {
            this.$body.addClass('use-scrollbar-hack');
        }

        this.$body.addClass('use-scrollbar-hack');
    },

    /**
     * Returns the jQuery reference for the given key
     * @param {string} key Key for the reference
     * @returns {object} The jquery object for the key
     */
    getRef: function(key) {
        if (!this.ui[key]) {
            throw new Error('Could not find selector "' + key + '"!');
        }

        if (!this.references[key]) {
            this.references[key] = $(this.ui[key]);
        }

        return this.references[key];
    },

    /**
     * Refreshes the jQuery reference and returns it
     * @param {string} key Key for the reference
     * @returns {object} The jquery object for the key
     */
    refreshRef: function(key) {
        if (!this.ui[key]) {
            throw new Error('Could not find selector "' + key + '"!');
        }

        this.references[key] = $(this.ui[key]);

        return this.references[key];
    },

    /**
     * Renders the UI
     */
    render: function() {
        // Update has scrolled
        this.$body.toggleClass('has-scrolled', this.scrollTop > 0);

        // Update last scroll top
        this.lastScrollTop = this.scrollTop;

        // Update footer in-view
        this.checkFooterPositioning();

        // Reset rendering
        this.isRendering = false;
    },

    /**
     * Checks the footer positioning
     */
    checkFooterPositioning: function() {
        var beforeFooter = this.getRef('beforeFooter');
        if (beforeFooter.length > 0) {
            // has footer in view
            this.$body.toggleClass('has-footer-inview', this.scrollTop > beforeFooter.offset().top - this.$win.height());
            // has footer almost in view
            this.$body.toggleClass('has-footer-almost-inview', this.scrollTop > beforeFooter.offset().top - this.$win.height() * 2);
        }
    },

    /**
     * Handles the ready event
     * @param {object} event The ready event
     */
    handleReady: function(event) {
        if ($(this.ui.shippingSelectorCountry).length > 0) {
            this.fetchShippingSelectorResult();
        }
    },

    /**
     * Fetches the result from the shipping selector
     */
    fetchShippingSelectorResult: function() {
        var selectField = $(this.ui.shippingSelectorCountry);
        var value = selectField.val();
        var path = selectField.data('path');
        var $container = $(this.ui.shippingSelectorContainer);

        $container.attr('aria-busy', 'true');

        var request = $.ajax(path, {
            data: {id: value},
        });

        request.done(function(response) {
            if (response.success) {
                var markup = ['<ul>'];

                response.dispatches.forEach(function(dispatch) {
                    var description = $('<div>' + dispatch.description + '</div>');
                    var days = description.find('.nobr').html();

                    markup.push('<li>');
                    markup.push('<h3>' + dispatch.name + ' <small>(' + days + ' ' + dispatch.phrase + ')</small></h3>');
                    markup.push('<ul>');

                    dispatch.costs.forEach(function(cost) {
                        markup.push('<li><p>');
                        if (cost.from || cost.till) {
                            markup.push(cost.phrase + ' ' + (cost.till || cost.from) + ': <strong>' + cost.value + '</strong>');
                        } else {
                            markup.push(cost.phrase + ': <strong>' + cost.value + '</strong>');
                        }
                        markup.push('</p></li>');
                    });

                    markup.push('</ul>');
                    markup.push('</li>');
                });

                markup.push('</ul>');

                $(this.ui.shippingSelectorResult).html(markup.join(''));
            }
        }.bind(this));

        request.always(function() {
            $container.attr('aria-busy', 'false');
        }.bind(this));
    },

    /**
     * Handles the load event
     * @param {object} event The load event
     */
    handleLoad: function(event) {
        // Check if hash contains show login
        if (window.location.hash.search(/show-login/) !== -1) {
            this.showModal('has-login-box');
            $('.login-form-area input[name="email"]').focus();
        }
    },

    /**
     * Handles the click event on an ajax link. We will navigate via push state
     * @param {object} event The click event
     */
    handleAjaxLinkClick: function(event) {
        event.preventDefault();

        var target = $(event.currentTarget),
            href = target.attr('href').replace(window.location.origin, '');

        // Push new state
        this.common.pushState(href, {
            type: 'default-site',
        });

        // Close modals
        this.closeAllModals();

        // Close mega dropdown
        document.documentElement.focus();
    },

    /**
     * Handles the click event on an main nav link
     * @param {object} event The click event
     */
    handleTouchMainNavClick: function(event) {
        var target = $(event.currentTarget);
        var parent = target.parent();

        if (parent.hasClass('has-dropdown') && !target.data('enableNavigate')) {
            event.preventDefault();
            $(this.ui.mainNavDropdownCell).find('> a').data('enableNavigate', null);
            target.data('enableNavigate', true);
        } else {
            this.handleAjaxLinkClick(event);
            target.blur();
        }
    },

    /**
     * Triggered when a na item is unfocussed. We will remove enableNavigate flag
     * @param {object} event The blur event
     */
    handleTouchMainNavBlur: function(event) {
        var target = $(event.currentTarget);
        target.find('> a').data('enableNavigate', null);
    },

    /**
     * Handles the click event on a link inside the side main nav
     * @param {object} event The click event
     */
    handleSideNavMainClick: function(event) {
        var target = $(event.currentTarget);
        var parent = target.parent();

        if (parent.hasClass('has-accordion')) {
            event.preventDefault();

            // Find related accordion
            var accordion = parent.find('.accordion');

            // Shrink open accordions
            $(this.ui.sideNavAccordion).each(function(index, element) {
                element = $(element);

                if (element.get(0) !== accordion.get(0) && element.attr('aria-expanded') === 'true') {
                    this.toggleAccordion(element);
                }
            }.bind(this));

            // Toggle accordion
            this.toggleAccordion(accordion);
        } else {
            this.handleAjaxLinkClick(event);
        }
    },

    /**
     * Toggles the accordion for the given target
     * @param {jQuery} target The target to toggle
     */
    toggleAccordion: function(target) {
        if (window.browser && window.browser.supportsWebAnimation) {
            var accordionBody = target.find('.accordion__body');
            var accordionBodyContent = accordionBody.find('> *');
            var contentHeight = Math.ceil(accordionBodyContent.outerHeight());
            var contentRemHeight = this.common.pxToRem(contentHeight);
            var animationDuration = 400; // We use static timing here
            var animationEasing = 'cubic-bezier(.4, 0, .42, .99)';
            var animationSteps = [
                {height: 0},
                {height: contentRemHeight + 'rem'},
            ];

            if (target.attr('aria-expanded') === 'false') {
                target.attr('aria-expanded', true);

                accordionBody.get(0).animate(animationSteps, {
                    duration: animationDuration,
                    easing: animationEasing,
                });
            } else {
                var animation = accordionBody.get(0).animate(animationSteps, {
                    duration: animationDuration,
                    direction: 'reverse',
                    easing: animationEasing,
                });

                // Set expanded state when animation is finished
                animation.onfinish = function(event) {
                    target.attr('aria-expanded', false);
                };
            }
        } else {
            if (target.attr('aria-expanded') === 'false') {
                target.attr('aria-expanded', true);
            } else {
                target.attr('aria-expanded', false);
            }
        }
    },

    /**
     * Handles the resize event
     * @param {object} event The resize event
     */
    handleResize: function(event) {
        this.scrollTop = this.$doc.scrollTop();
        this.render();
    },

    /**
     * Handles the state change event
     * @param {object} event The state change event
     */
    handleStateChange: function(event) {
        event = event.originalEvent || event;

        var stateType = null;

        if (event.state) {
            if (event.state.type) {
                stateType = event.state.type;
            }
        }

        if (stateType === 'default-site') {
            // Fetch the content
            this.common.fetchContent(event.state.url, {
                scrollTop: true,
            });
        }

        // Render
        this.scrollTop = this.$doc.scrollTop();
        this.refreshRef('beforeFooter');
        this.render();
    },

    /**
     * Renders the view, after ajax request is completed
     * @param event
     */
    renderAfterAjaxSuccess: function(event) {
        // Render
        this.scrollTop = this.$doc.scrollTop();
        this.refreshRef('beforeFooter');
        this.render();

        if ($(this.ui.shippingSelectorCountry).length > 0) {
            this.fetchShippingSelectorResult();
        }
    },

    /**
     * Handles the scroll event
     * @param {object} event The scroll event
     */
    handleScroll: function(event) {
        this.scrollTop = this.$doc.scrollTop();
        this.hasUserScrolled = true;

        // Request an animation frame, when the page is not rendering
        if (!this.isRendering || this.scrollTop == 0) {
            window.requestAnimationFrame(this.render.bind(this));
            this.isRendering = true;
        }
    },

    /**
     * Handles the click on the nav icon
     * @param {object} event The click event
     */
    handleNavIconClick: function(event) {
        event.preventDefault();

        this.closeModal();
        this.showModal('has-nav-main');
    },

    /**
     * Handles the click on the cart icon
     * @param {object} event The click event
     */
    handleCartClick: function(event) {
        event.preventDefault();

        this.closeModal();
        this.showModal('has-mini-cart');
    },

    /**
     * Handles the click on the localisation
     * @param {object} event The click event
     */
    handleLocalisationClick: function(event) {
        event.preventDefault();

        var $target = $(event.currentTarget);

        this.closeModal();
        this.showModal('has-localisation');

        $(this.ui.localisationModal).attr('aria-busy', 'true');

        var request = $.ajax($target.attr('href'));
        request.done(function(response) {
            $(this.ui.localisationModal).find('.modal-content').html(response);
            $(this.ui.localisationModal).attr('aria-busy', 'false');

            this.countrySelectionSearchVal = '';

            // Scroll selected into view
            var $selected = $(this.ui.localisationModal).find('.country-selection-list__country[aria-selected="true"]');
            if ($selected.length && 'scrollIntoViewIfNeeded' in $selected[0]) {
                setTimeout(function() {
                    $selected[0].scrollIntoView({block: 'center'});
                }, 1);
            }
        }.bind(this));
    },

    /**
     * Handles the click on the search icon
     * @param {object} event The click event
     */
    handleSearchClick: function(event) {
        event.preventDefault();

        if (this.$body.hasClass('has-search')) {
            this.closeModal();
        } else {
            this.showModal('has-search');
            $(this.ui.searchField).focus();
        }
    },

    /**
     * Handles the click on the login icon
     * @param {object} event The click event
     */
    handleLoginClick: function(event) {
        event.preventDefault();

        this.closeModal();
        this.showModal('has-login-box');
        $('.login-form-area input[name="email"]').focus();
    },

    /**
     * Shows the modal for the given class
     * @param cls
     */
    showModal: function(cls) {
        this.$body.addClass(cls);
        Postal.channel('site').publish('modal:opened', {
            cls: cls,
        });
    },

    /**
     * Closes the modals
     * @param {boolean=} closeAll (Optional) Close all modals?
     */
    closeModal: function(closeAll) {
        if (typeof closeAll !== 'boolean') {
            closeAll = false;
        }

        var classes = [
            'has-mini-cart',
            'has-search',
            'has-login-box',
            'has-localisation',
            'has-frame-notification',
        ];

        if (closeAll) {
            classes.push('has-nav-main');
        }

        // Remove classes
        classes.forEach(function(cls) {
            if (this.$body.hasClass(cls)) {
                Postal.channel('site').publish('modal:closed', {
                    cls: cls,
                });
            }

            this.$body.removeClass(cls);
        }.bind(this));
    },

    /**
     * Shows the filter
     * @param
     */
    handleFilterTrigger: function() {
        this.$body.toggleClass('has-filter');
        var filterWrapper = $('.filter-wrapper');
        if (filterWrapper.scrollTop() > 0) {
            filterWrapper.scrollTop(0);
        }
    },

    /**
     * Hides the filter
     * @param
     */
    handleFilterDismiss: function() {
        this.$body.removeClass('has-filter');
        var filterWrapper = $('.filter-wrapper');
        if (filterWrapper.scrollTop() > 0) {
            filterWrapper.scrollTop(0);
        }
    },

    /**
     * Shorthand method to close all modals
     */
    closeAllModals: function() {
        this.closeModal(true);
    },

    /**
     * Displays the loading layer
     * @param {boolean=} slim (Optional) show slim loader
     */
    showSiteLoading: function(slim) {
        slim = slim || false;
        this.common.showMainLoader(slim);
    },

    /**
     * Hides the loading layer
     */
    hideSiteLoading: function() {
        this.common.hideMainLoader();
    },

    /**
     * Handles the default loading state for a form
     * @param {object} event The submit event of the form
     */
    handleDefaultLoadingForm: function(event) {
        var curTarget = $(event.currentTarget);

        // Mark as loading
        curTarget.attr('data-state', 'loading');

        // Disable buttons
        curTarget.find('input[type="submit"], button[type="submit"]').prop('disabled', true);

        // Show general loading
        this.showSiteLoading(true);
    },

    /**
     * Triggered, when the localisation form is submitted
     * @param {Event} event The submit event
     */
    onLocalisationFormSubmit: function(event) {
        event.preventDefault();

        var $form = $(event.currentTarget);
        var path = $form.attr('action');
        var country = $(this.ui.countrySelectionCurrent).val();

        var request = $.ajax(path, {
            data: {country: country},
            method: 'POST',
            dataType: 'json',
        });

        request.done(function(response) {
            if (response.needsReload) {
                window.location.reload();
            } else {
                $form.removeAttr('data-state');
                $form.find('input[type="submit"], button[type="submit"]').prop('disabled', false);
                this.hideSiteLoading();
            }
        }.bind(this));

        request.fail(function() {
            $form.removeAttr('data-state');
            $form.find('input[type="submit"], button[type="submit"]').prop('disabled', false);
            this.hideSiteLoading();
        }.bind(this));
    },

    /**
     * Triggered, when a country is clicked
     * @param {Event} event The click event
     */
    onCountrySelectionClick: function(event) {
        event.preventDefault();

        var $target = $(event.currentTarget);
        this.selectCountry($target);
    },

    /**
     * Triggered, on change/input events of the search field
     * @param {Event} event The input/change event
     */
    onCountrySelectionSearchInput: function(event) {
        var $target = $(event.currentTarget);
        var val = $target.val().trim();

        // Skip if val has not changed
        if (val === this.countrySelectionSearchVal) {
            return;
        }

        // Update value
        this.countrySelectionSearchVal = val;

        // Check countries
        var $countries = $(this.ui.countrySelectionCountry);
        var $country, alternative, isVisible;
        $countries.each(function(idx, element) {
            $country = $(element);
            alternative = $country.data('alternativeSpellings') || '';
            isVisible = val.length === 0
                || $country.text().toUpperCase().indexOf(val.toUpperCase()) >= 0
                || alternative.toUpperCase().indexOf(val.toUpperCase()) >= 0;

            $country.attr('aria-hidden', isVisible ? 'false' : 'true');
        });

        // Check surrounding groups
        var $group, $visibleCountries;
        $(this.ui.countrySelectionGroup).each(function(idx, element) {
            $group = $(element);
            $visibleCountries = $group.find(this.ui.countrySelectionCountry + '[aria-hidden="false"]');
            $group.attr('aria-hidden', $visibleCountries.length > 0 ? 'false' : 'true');
        }.bind(this));

        // Select first country
        if ($countries.filter('[aria-hidden="false"]').length > 0) {
            this.countrySelectionIndex = 0;
            this.selectCountry($countries.filter('[aria-hidden="false"]').eq(this.countrySelectionIndex));
        }
    },

    /**
     * Handles the keydown event in the localisation search input field
     * @param {Event} event The keydown event
     */
    onCountrySelectionSearchKeydown: function(event) {
        if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
            return;
        }

        event.preventDefault();

        var $countries = $(this.ui.countrySelectionCountry).filter('[aria-hidden="false"]');

        if (event.key === 'ArrowUp' && this.countrySelectionIndex > 0) {
            this.countrySelectionIndex--;
            this.selectCountry($countries.eq(this.countrySelectionIndex));
        } else if (event.key === 'ArrowDown' && this.countrySelectionIndex + 1 < $countries.length) {
            this.countrySelectionIndex++;
            this.selectCountry($countries.eq(this.countrySelectionIndex));
        }
    },

    /**
     * Selects the given country
     * @param {jQuery} $country The related country
     */
    selectCountry: function($country) {
        // Remove previous selected
        $(this.ui.countrySelectionCountry).filter('[aria-selected="true"]').attr('aria-selected', 'false');

        // Assign new values
        $country.attr('aria-selected', 'true');
        $(this.ui.countrySelectionCurrent).val($country.data('id'));
    },

    /**
     * Handles the click on the close button inside the free shipping banner
     * @param event
     */
    handleFreeShippingCloseClick: function(event) {
        event.preventDefault();

        this.$body.removeClass('has-free-shipping-banner');

        Cookies.set('free-shipping-shown', 1, {expires: 30});
    },

    /**
     * Handles the click event of the select choices button
     * @param {Event} event The click event
     */
    handleCookkieSelectChoicesClick: function(event) {
        event.preventDefault();
        this.saveCookieConsent();
    },

    /**
     * Handles the click event on the select all button
     * @param {Event} event The click event
     */
    handleCookkieSelectAllClick: function(event) {
        event.preventDefault();

        var $container = $(this.ui.cookieConsentContainer);
        $container.find('input[type="checkbox"]').prop('checked', true);

        this.saveCookieConsent();
    },

    /**
     * Saves the settings for the cookie consent
     */
    saveCookieConsent: function() {
        var $container = $(this.ui.cookieConsentContainer);

        var cookiesToSet = ['specs-cookie-consent'];
        $container.find('input[type="checkbox"]:checked').each(function(idx, element) {
            cookiesToSet.push($(element).attr('name'));
        });

        cookiesToSet.forEach(function(name) {
            Cookies.set(name, 1, {expires: 365})
        });

        $container.attr('aria-hidden', 'true');

        window.setTimeout(function() {
            window.location.reload();
        }, 100);
    },

    /**
     * Handles the click event on a modal iframe link
     * @param {Event} event The click event
     */
    handleModalWindowOpenClick: function(event) {
        event.preventDefault();

        var $target = $(event.currentTarget);
        var href = $target.attr('href');

        if (window.browser && window.browser.isIos) {
            window.open(href);
            return;
        }


        var $modalWindow = this.getRef('modalWindow');
        var $modalWindowIframe = $modalWindow.find(this.ui.modalWindowIframe);

        if ($modalWindowIframe.attr('src') !== href) {
            $modalWindowIframe.attr('src', href);
        }

        if ($target.data('title')) {
            $modalWindow.find('h3').html($target.data('title'));
        }

        $modalWindow.attr('aria-hidden', 'false');
        this.$body.addClass('has-modal-window');
    },

    /**
     * Handles the click event on the modal window close trigger
     * @param {Event} event The click event
     */
    handleModalWindowCloseClick: function(event) {
        event.preventDefault();

        var $modalWindow = this.getRef('modalWindow');

        $modalWindow.attr('aria-hidden', 'true');
        this.$body.removeClass('has-modal-window');
    },
};

module.exports = SpecsSite;
