// Import vendor stuff
var lazySizes = require('vendor/lazysizes-1.3.1');

// Import local stuff
var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsLazyload = function(options) {
    this.init(options);
};

SpecsLazyload.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Include custom functionality
        this.setupConfig();
        this.enablePreviewPicture();
        this.enablePrintReveal();
    },

    /**
     * Merges the custom config with our config
     */
    setupConfig: function() {
        var mergeConfig = {
            lazyClass   : 'lazy lazy--pending',
            loadedClass : 'lazy lazy--loaded',
            loadingClass: 'lazy lazy--loading',
            preloadClass: 'lazy lazy--preload',
            errorClass  : 'lazy lazy--error',
        };


        for (var prop in mergeConfig){
            window.lazySizesConfig[prop] = mergeConfig[prop];
        }
    },

    /**
     * Enables the Preview Picture event
     */
    enablePreviewPicture: function() {
        if (window.addEventListener) {
            addEventListener('lazybeforeunveil', this.onImageLoaded.bind(this), false);
        }
    },

    /**
     * Is called, when an image has been loaded
     * @param {object} event The lazybeforeunveil event
     */
    onImageLoaded: function(event) {
        var target = $(event.target),
            parent = target.parent();

        if (parent.hasClass('product-image--lazy')) {
            parent.addClass('product-image--loaded');
        }
    },

    /**
     * Enables print reveal option
     * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/print
     */
    enablePrintReveal: function() {
        var config, elements, onprint, printMedia;
        if (window.addEventListener) {
            config   = (window.lazySizes && lazySizes.cfg) || window.lazySizesConfig || {};
            elements = config.lazyClass || 'lazyload';
            onprint  = function() {
                var i, len;
                if (typeof elements == 'string') {
                    elements = document.getElementsByClassName(elements);
                }

                if (window.lazySizes) {
                    for (i = 0, len = elements.length; i < len; i++) {
                        lazySizes.loader.unveil(elements[i]);
                    }
                }
            };

            addEventListener('beforeprint', onprint, false);

            if (!('onbeforeprint' in window)
                && window.matchMedia
                && (printMedia = matchMedia('print'))
                && printMedia.addListener
            ) {
                printMedia.addListener(function() {
                    if (printMedia.matches) {
                        onprint();
                    }
                });
            }
        }
    },
};

module.exports = SpecsLazyload;