/**
 * Function to parse a query string
 * @param {string} search The query string to parse
 * @returns {object} Object with parsed args
 */
var parseQuery = function parseQuery(search) {
    var args = search.substring(0).split(/[&\?]+/);
    var argsParsed = {};
    var i, arg, kvp, key, value;
    for (i = 0; i < args.length; i++) {
        arg = args[i];
        if (-1 === arg.indexOf('=')) {
            argsParsed['~'] = args[i];
        } else {
            kvp = arg.split('=');
            key = decodeURIComponent(kvp[0]).trim();
            value = decodeURIComponent(kvp[1]).trim();
            argsParsed[key] = value;
        }
    }

    return argsParsed;
};

module.exports = parseQuery;