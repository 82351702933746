var SpecsCommon = require('specs/common');
var ImportOptions = require('helper/import-options');
var Postal = require('vendor/postal-1.0.7');

/**
 * Definition of the Specs Filter
 * @param {object=} options Options/References to apply
 * @constructor
 */
var SpecsSearch = function(options) {
    this.init(options);
}

SpecsSearch.prototype = {
    /**
     * Reference to our common component
     * @type {null}|{SpecsCommon}
     */
    common: null,

    /**
     * UI ref classes
     */
    ui: {
        searchField       : '.header-search input[type="search"]',
        searchForm        : '.header-search form',
        searchDismiss     : '.btn[data-action="dismiss-search"]',
    },

    /**
     * Initializes the filter object
     * @param {object=} options Options/References to apply
     */
    init: function(options) {
        // Common stuff
        ImportOptions(this, options);
        this.common = SpecsCommon.getInstance();

        // Bind events
        this.bindUiEvents().bindCustomEvents();
    },

    /**
     * Binds UI events, when ready is fired
     * @return {SpecsSearch}
     */
    bindUiEvents: function() {
        this.$body.on('submit', this.ui.searchForm, this.handleSearchFormSubmit.bind(this));
        this.$body.on('click', this.ui.searchDismiss, this.handleSearchDismiss.bind(this));
        this.$body.on('input change blur', this.ui.searchField, this.handleSearchHint.bind(this));

        return this;
    },

    /**
     * Binds custom events trough postal
     * @return {SpecsSearch}
     */
    bindCustomEvents: function() {
        this.$win.on('popstate', this.handleStateChange.bind(this));
        Postal.channel('common').subscribe('pushstate', this.handleStateChange.bind(this));

        return this;
    },

    /**
     * Handles the operation, when search was submitted
     * @param {object} event The submit event
     */
    handleSearchFormSubmit: function(event) {
        event.preventDefault();

        var keyword = $(this.ui.searchField).val();
        var searchAction = $(this.ui.searchForm).attr('action');

        // Push new state
        this.common.pushState(searchAction + '?sSearch=' + keyword, {
            type: 'search',
            keyword: keyword,
            action: searchAction,
        });

        // Publish event
        Postal.channel('site').publish('search:triggered', {
            keyword: keyword,
        });
    },

    /**
     * Dismisses the search field
     * @param {object} event The click event
     */
    handleSearchDismiss: function(event) {
        event.preventDefault();

        var searchField = $(this.ui.searchField),
            searchForm = $(this.ui.searchForm);

        searchForm[0].reset();
        searchField.val('');
        searchForm.removeClass('has-search-hint');
        Specs.Site.closeModal();
    },

    /**
     * Handles changes in the search field
     * @param {object} event
     */
    handleSearchHint: function(event) {
        var searchField = $(this.ui.searchField),
            searchForm = $(this.ui.searchForm);

        searchForm.toggleClass('has-search-hint', searchField.val().length > 1);
    },

    /**
     * Handles the state change event
     * @param {object} event The state change event
     */
    handleStateChange: function(event) {
        event = event.originalEvent || event;

        var stateType = null,
            stateKeyword = null,
            stateAction = null;

        if (event.state) {
            if (event.state.type) {
                stateType = event.state.type;
            }

            if (event.state.keyword) {
                stateKeyword = event.state.keyword;
            }

            if (event.state.action) {
                stateAction = this.common.getProperUrl(event.state.action);
            }
        }

        // Type is search? -> Fetch content
        if (stateType === 'search' && stateKeyword) {
            var url = event.state.url || stateAction + '?sSearch=' + stateKeyword;
            $(this.ui.searchField).val(stateKeyword);
            this.common.fetchContent(url, {
                scrollTop: true,
            });
        }
    },
};

module.exports = SpecsSearch;