/**
 * Static Object, which contains helpful methods to attach certain browser classes
 * @package humans/frontend
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
var HumansFrontendBrowserClasses = {

    /**
     * Attaches useful classes to the document
     */
    attach: function() {
        window.browser = {
            isIos: this.detectIos(),
            isAndroid: /Android/i.test(navigator.userAgent),
            isMobileSafari: navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('CriOS') < 0,
            isMobile: /iPhone|iPod|iPad|Android|BlackBerry|Opera Mini|IEMobile|WindowsPhone/.test(navigator.userAgent),
            isIE: this.detectIE(),
            isHomescreen: window.navigator.standalone,
            has: {
                touch: this.detectTouchDevice(),
            },
            insideIframe: window.self !== window.top,
            supportsWebAnimation: 'animate' in document.documentElement,
        };

        // Add some additional classes
        var documentClasses = [
            'js',
            window.browser.isIos                ? 'ios'               : 'no-ios',
            window.browser.isAndroid            ? 'android'           : 'no-android',
            window.browser.isMobile             ? 'mobile'            : 'no-mobile',
            window.browser.isHomescreen         ? 'homescreen'        : 'no-homescreen',
            window.browser.has.touch            ? 'touch'             : 'no-touch',
            window.browser.isIE                 ? 'ie'                : 'no-ie',
            window.browser.insideIframe         ? 'iframe'            : 'no-iframe',
            window.browser.supportsWebAnimation ? 'web-animation-api' : 'no-web-animation-api',
        ];

        document.documentElement.className = document.documentElement.className + ' ' + documentClasses.join(' ');
    },

    /**
     * Detects the IE browser version
     * @returns {number|boolean} False, if browser is not an IE. The version number, if browser is IE.
     */
    detectIE: function() {
        var userAgent = window.navigator.userAgent;

        var msie = userAgent.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10);
        }

        var trident = userAgent.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var versionNumber = userAgent.indexOf('rv:');

            return parseInt(userAgent.substring(versionNumber + 3, userAgent.indexOf('.', versionNumber)), 10);
        }

        var edge = userAgent.indexOf('Edge/');
        if (edge > 0) {
            // IE 12 => return version number
            return parseInt(userAgent.substring(edge + 5, userAgent.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    /**
     * Indicates if browser is safari
     * @return {boolean} True, if browser is safari
     */
    detectIos: function() {
        return (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !window.MSStream;
    },

    /**
     * Helper function, to indicate if we have a touch device
     * @return {Boolean} True, if it is a touch device
     */
    detectTouchDevice: function() {
        return 'ontouchstart' in window || navigator.maxTouchPoints || matchMedia('(pointer:coarse)').matches;
    },
};

module.exports = HumansFrontendBrowserClasses;